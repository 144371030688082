import { useMemo } from 'react';
import { usePulse5Features } from '@subApps/navApp/hooks/useData';
import type { MenuConfig } from '../components/Menu/type';

export function useValidConfig(config: MenuConfig) {
  const { data: features } = usePulse5Features();
  return useMemo(() => {
    const newGroups = (config.groups || []).map(group => {
      return group.filter(item => {
        if ('show' in item) {
          return typeof item.show === 'function'
            ? item.show({ features, nav: undefined, user: undefined })
            : item.show;
        }
        return true;
      });
    });
    return {
      ...config,
      groups: newGroups,
    };
  }, [config, features]);
}
