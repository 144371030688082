import { get, remove } from 'js-cookie';

export const checkAppAccessByCookie = (appName: string) => {
  const tokenName =
    location.origin === 'https://pulse.conviva.com' ? 'accessToken' : 'webappgcp_accessToken';

  const val = get(tokenName);

  if (!val) {
    return false;
  }

  try {
    const { applications = [] } = JSON.parse(
      atob(JSON.parse(decodeURIComponent(val)).accessToken.split('.')[1]),
    );
    return (applications as string[]).includes(appName);
  } catch {
    return false;
  }
};

export const isSIOnly = () => get('SIO') === 'true';

export const clearSIOnlyFlag = () => {
  remove('SIO');
};
