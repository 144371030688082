import React from 'react';
import { get } from 'lodash';
import { PRODUCT_DISPLAY_NAMES_NEW_NAV, PRODUCTS } from '@constants/products';
import {
  AI_ALERTS_PULSE5,
  AI_ROADMAP,
  MANUAL_ALERTS,
  METRIC_LENS,
  OVERVIEW_DASHBOARD,
  REAL_TIME,
  VIEWER_MODULE_PULSE5,
  RESILIENCY,
  PROMPT,
} from '@subApps/navApp/hooks/useData/usePulse5Features/featureNames';
import VideoIcon from '@resources/icons/product/Video.svg';
import { Menu } from '../../components/Menu';
import { useConfigWithSavedDashboard } from '../Dashboard/hooks';
import type { MenuConfig } from '../../components/Menu/type';
import { useValidConfig } from '../../hooks/useValidConfig';

const config: MenuConfig = {
  product: PRODUCTS.EI,
  icon: <VideoIcon />,
  displayName: PRODUCT_DISPLAY_NAMES_NEW_NAV[PRODUCTS.EI],
  groups: [
    [
      {
        id: 'overview-dashboard',
        category: PRODUCTS.EI,
        displayName: 'Overview',
        url: '/app/experience-insights/dashboard/overview',
        valid: ({ features }) => get(features, OVERVIEW_DASHBOARD, false),
      },
      {
        id: 'trends',
        category: PRODUCTS.EI,
        displayName: 'Trends',
        url: '/app/experience-insights/dashboard/trends',
        valid: () => true,
      },
      {
        id: 'metric-lens',
        category: PRODUCTS.EI,
        displayName: 'MetricLens',
        url: '/app/experience-insights/dashboard/metriclens',
        valid: ({ features }) => get(features, METRIC_LENS, false),
      },
      {
        id: 'real-time',
        category: PRODUCTS.EI,
        displayName: 'Real-Time',
        url: '/app/experience-insights/dashboard/real-time',
        valid: ({ features }) => get(features, REAL_TIME, false),
      },
      {
        id: 'unified-ai-alerts',
        category: PRODUCTS.EI,
        displayName: 'AI Alerts',
        url: '/app/experience-insights/dashboard/ai-alerts',
        valid: ({ features }) => get(features, AI_ALERTS_PULSE5, false),
      },
      {
        id: 'ma-alerts',
        category: PRODUCTS.EI,
        displayName: 'Manual Alerts',
        url: '/app/experience-insights/alert/manual/summary',
        valid: ({ features }) => get(features, MANUAL_ALERTS, false),
      },
      {
        id: 'ai-roadmap',
        category: PRODUCTS.EI,
        displayName: 'AI Roadmap',
        url: '/app/experience-insights/dashboard/ai-roadmap',
        valid: ({ features }) => get(features, AI_ROADMAP, false),
      },
      {
        id: 'ei-prompt',
        category: PRODUCTS.EI,
        displayName: 'PromptAI',
        url: '/app/experience-insights/dashboard/prompt',
        valid: ({ features }) => {
          return get(features, PROMPT, false);
        },
      },
      {
        id: 'viewers',
        category: PRODUCTS.EI,
        displayName: 'Viewers',
        url: '/app/experience-insights/dashboard/viewer-module',
        valid: ({ features }) => get(features, VIEWER_MODULE_PULSE5, false),
      },
      {
        id: 'ei-customizable-dashboard',
        category: PRODUCTS.EI,
        displayName: 'Custom Dashboards',
        url: '/app/custom-dashboards?source=ei',
        valid: () => true,
        target: '_blank',
      },
    ],
    [
      {
        id: 'precision',
        category: PRODUCTS.EI,
        displayName: 'Precision',
        type: 'group',
        children: [
          {
            id: 'policies',
            category: PRODUCTS.EI,
            displayName: 'Policies',
            url: '/app/precision/precision_policy',
            valid: ({ nav }) => nav?.menu.precision?.some(m => m.name === 'Policies'),
            target: '_blank',
          },
          {
            id: 'policy-tracking',
            category: PRODUCTS.EI,
            displayName: 'Policy Tracking',
            url: '/app/precision/policies/tracking',
            valid: ({ nav }) => nav?.menu.precision?.some(m => m.name === 'Policy Tracking'),
            target: '_blank',
          },
          {
            id: 'resiliency-new',
            category: PRODUCTS.EI,
            displayName: 'Resiliency Tracking',
            url: '/app/precision/resiliency',
            valid: ({ features }) => {
              return get(features, RESILIENCY, false);
            },
            target: '_blank',
          },
        ],
      },
    ],
  ],
};

const Video = () => {
  const validConfig = useValidConfig(config);
  const menuConfig = useConfigWithSavedDashboard(
    PRODUCTS.EI,
    validConfig,
    'ei-customizable-dashboard',
  );
  return <Menu config={menuConfig} />;
};

export default Video;
