import React from 'react';
import Icon from '@ant-design/icons';
import { useProduct } from '@subApps/navApp/hooks/useGlobal';
import { getIcon, ICON_NAMES } from '@resources/icons';
import PRODUCTS from '@constants/products';
import { Button, Space } from 'antd';
import { StyledDivider } from '../styled';

const AutomaticInsights = ({ compact = false }: { compact?: boolean }) => {
  const { data: product } = useProduct();

  // only display automatic insights for EI, Alert is point to EI alert for now, it's part of EI
  if (![PRODUCTS.EI].includes(product)) {
    return null;
  }

  const handleChange = () => {
    const event: any = new CustomEvent('auto-insights-drawer', {
      detail: { visible: true },
    });
    document.dispatchEvent(event);
  };

  return (
    <Space>
      <StyledDivider style={{ height: 30 }} type="vertical" />
      <Button
        type="primary"
        ghost
        onClick={handleChange}
        icon={
          <Icon component={getIcon(ICON_NAMES.bulb)} style={{ marginRight: !compact ? -3 : 0 }} />
        }
        style={{ padding: 5 }}
      >
        {!compact && 'Auto Insights'}
      </Button>
    </Space>
  );
};

export default React.memo(AutomaticInsights);
