import useCheckActiveCustomer from '@subApps/navApp/hooks/useData/useCheckActiveCustomer';
import { get } from 'lodash';
import useUser from '@subApps/navApp/hooks/useData/useUser';

/*
  Setting up a component to check the current active customer.

  Adding this component as this would eliminate the requirement of a user interaction before checking the
  customer detail in IF Server middleware.

  If a user has multiple tabs open and they swtich c3 in one of the tabs, then requests from the other tabs
  would query with new customer id. This can be an issue with dashboards with Live mode.
  Below component's hook will call the IF Server on window refocus and would reload the page if IF Server responds
  with HTTP Code 205.
*/

const CheckActiveCustomer = () => {
  const { data: user } = useUser();
  const customerId = get(user, 'self.customerId', null);
  useCheckActiveCustomer(customerId);
  return null;
};

export default CheckActiveCustomer;
