import React from 'react';
import qs from 'query-string';
import { PRODUCTS, PRODUCT_URL_PREFIX } from '@constants/products';
import { isSIOnly } from '@utils/cookie';
import { SUB_MENUS } from '@constants/subMenu';
import { useProduct } from '.';

const AI_PATH = ['/app/pulse/reports/60', '/app/pulse/alerts4/adi'];

const mdsPath = ['/app/pulse/mds'];
const appManagerPath = ['/app/pulse/appManager'];

// setting path, exclude AI_PATH
const settingsPath = (() => {
  const paths = Object.values(SUB_MENUS).reduce((path, menus) => {
    menus.forEach(m => {
      if (m.url && typeof m.url === 'string') {
        path.push(m.url);
      }
    });
    return path;
  }, [] as string[]);
  return paths.concat([
    '/app/pulse/reports/25',
    '/app/pulse/alerts4/manage_subscription',
    '/app/pulse/alerts4/app/manage_subscription',
  ]);
})();

export function setProductsByPathname(pathname, setProduct) {
  if (settingsPath.some(p => pathname.startsWith(p))) {
    const { product } = qs.parse(window.location.search);
    if (typeof product === 'string' && Object.values(PRODUCTS).includes(product as PRODUCTS)) {
      setProduct(product as PRODUCTS);
    }

    return;
  }

  if (isSIOnly()) {
    setProduct(PRODUCTS.SI);
    return;
  }

  if (appManagerPath.includes(pathname)) {
    setProduct(PRODUCTS.appManager);
    return;
  }
  if (mdsPath.includes(pathname)) {
    setProduct(PRODUCTS.metadataManager);
    return;
  }
  if (pathname.startsWith(PRODUCT_URL_PREFIX.VDASH)) {
    setProduct(PRODUCTS.VDASH);
    return;
  }
  /* eslint-disable no-restricted-syntax */
  for (const [product, prefix] of Object.entries(PRODUCT_URL_PREFIX)) {
    if (pathname.startsWith(prefix)) {
      if ([PRODUCTS.execReport, PRODUCTS.admin].includes(product as PRODUCTS)) {
        setProduct(PRODUCTS.EI);
      } else if (product === PRODUCTS.pulse4) {
        if (AI_PATH.some(p => pathname.startsWith(p))) {
          setProduct(PRODUCTS.AI);
        } else {
          setProduct(PRODUCTS.EI);
        }
      } else {
        setProduct(product as PRODUCTS);
      }
      return;
    }
  }
}

// set initial product when users access url directly.
export default () => {
  const { onStateChange: setProduct } = useProduct();

  React.useEffect(() => {
    setProductsByPathname(window.location.pathname, setProduct);
  }, []);
};
