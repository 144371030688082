import React, { useState } from 'react';
import { Col, Drawer, Row, Input, Select, Space, Typography, Button, Form } from 'antd';
import { useAtom } from 'jotai';
import { openContactDrawerAtom } from '@subApps/navApp/Atoms/HelpAtoms';
import { contactSupportAPI } from '@subApps/navApp/hooks/useData/useContactSupport';
import { get } from 'lodash';
import { trackEvent } from '@subApps/navApp/hooks/useGlobal/useSensor';

const { TextArea } = Input;
const { Text, Link, Paragraph } = Typography;
const inputStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  color: '#3b3b40',
  fontFamily: "'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
};

const priorityOptions = [
  { value: 'P0', label: 'P0 - Critical' },
  { value: 'Pri1', label: 'P1 - Urgent' },
  { value: 'P2', label: 'P2 - Standard' },
];
const featuresOptions = [
  { value: '', label: '-None-' },
  { value: 'Ad Insight', label: 'Ad Insight' },
  { value: 'Alerts', label: 'Alerts' },
  { value: 'Community', label: 'Community' },
  { value: 'Device Validation', label: 'Device Validation' },
  { value: 'Insight API', label: 'Insight API' },
  { value: 'Live Events', label: 'Live Events' },
  { value: 'Personal Data', label: 'Personal Data' },
  { value: 'Player', label: 'Player' },
  { value: 'Portal', label: 'Portal' },
  { value: 'Precision', label: 'Precision' },
  { value: 'Pulse', label: 'Pulse' },
  { value: 'Reports', label: 'Reports' },
  { value: 'SSD', label: 'SSD' },
  { value: 'Touchstone', label: 'Touchstone' },
  { value: 'VAI  Alerts', label: 'VAI  Alerts' },
  { value: 'Viewer API', label: 'Viewer API' },
  { value: 'Viewer Module', label: 'Viewer Module' },
];
const HelpSideBar = () => {
  const [openContactDrawer, setOpenContactDrawerAtom] = useAtom(openContactDrawerAtom);
  const [subject, setSubject] = useState('');
  const [summary, setSummary] = useState('');
  const [priority, setPriority] = useState('P2');
  const [feature, setFeature] = useState('none');
  const [thankYou, setThankYou] = useState(false);
  const [error, setError] = useState(false);
  const [disableSendButton, setDisableSendButton] = useState(false);

  const resetEverything = () => {
    setSubject('');
    setSummary('');
    setPriority('P2');
    setFeature('none');
    setThankYou(false);
    setDisableSendButton(false);
    setOpenContactDrawerAtom(false);
    setError(false);
  };

  const onClose = () => {
    resetEverything();
  };

  const onSubmit = async () => {
    const buttonEventName = 'CONTACT_SUPPORT_SEND_BUTTON_CLICK';
    setDisableSendButton(true);
    const payload = {
      subject,
      description: summary,
      priority,
      feature,
    };
    trackEvent({ name: buttonEventName, data: payload });
    const response: any = await contactSupportAPI(payload);
    const responseStatus = get(response, 'data.status', null);
    if (responseStatus === 200) {
      setThankYou(true);
      setTimeout(() => {
        resetEverything();
      }, 2000);
    } else {
      setError(true);
    }
  };

  const title = `Contact Us`;
  const termsOfUse = (
    <Link href="/legal">
      <u>Terms of Use</u>
    </Link>
  );

  const getForm = () => (
    <Form name="validateOnly" layout="vertical" autoComplete="off" onFinish={onSubmit}>
      <Form.Item
        name="Subject"
        rules={[{ required: subject.length < 6, message: 'Min 6 Characters' }]}
      >
        <Text style={inputStyle}>Subject</Text>
        <Input value={subject} onChange={e => setSubject(e.target.value)} />
      </Form.Item>

      <Form.Item
        name="Summary"
        rules={[{ required: !summary.length, message: 'Please Enter Summary' }]}
      >
        <Text style={inputStyle}>Summary:</Text>
        <TextArea rows={10} value={summary} onChange={e => setSummary(e.target.value)} />
      </Form.Item>

      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <Form.Item name="Priority">
            <Text style={inputStyle}>Priority</Text>
            <Select
              defaultValue={priority}
              options={priorityOptions}
              onChange={value => setPriority(value)}
            />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={12}>
          <Form.Item name="Feature">
            <Text style={inputStyle}>Feature</Text>
            <Select
              defaultValue={feature}
              options={featuresOptions}
              onChange={value => setFeature(value)}
            />
          </Form.Item>
        </Col>
      </Row>

      <div style={{ bottom: 0, position: 'absolute', width: '100%' }}>
        <hr />
        <Row gutter={16}>
          <Col className="gutter-row" span={2.5}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Space>
                <Button type="primary" htmlType="submit" ghost disabled={disableSendButton}>
                  Send
                </Button>
              </Space>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={2.5}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Space>
                <Button onClick={onClose}>Cancel</Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <h5 style={{ textAlign: 'center' }}>
          <p>Conviva {termsOfUse} © 2024 Conviva. All rights reserved.</p>
        </h5>
      </div>
    </Form>
  );

  const getSuccessOrErrorMessage = () => {
    if (error) {
      return (
        <Paragraph>
          <div style={{ color: 'red' }}> There was some issue. Please try again!! </div>
        </Paragraph>
      );
    }
    if (thankYou) {
      return (
        <Paragraph>
          You Will Get A Confirmation Email Shortly. Thank You For Contacting Conviva Support.{' '}
        </Paragraph>
      );
    }
  };

  return (
    <div>
      <Drawer
        title={title}
        placement="right"
        onClose={onClose}
        open={openContactDrawer}
        size="large"
      >
        {getSuccessOrErrorMessage()}
        {!error && !thankYou ? getForm() : ''}
      </Drawer>
    </div>
  );
};

export default HelpSideBar;
