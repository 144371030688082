import React from 'react';
import { PRODUCTS, PRODUCT_DISPLAY_NAMES_NEW_NAV } from '@constants/products';
import App from '@resources/icons/product/App.svg';
import { get } from 'lodash';
import { MINI_ODP_ACCESS } from '@subApps/navApp/hooks/useData/usePulse5Features/featureNames';
import { Menu } from '../../components/Menu';

const config = {
  product: PRODUCTS.ODP,
  icon: <App />,
  displayName: PRODUCT_DISPLAY_NAMES_NEW_NAV[PRODUCTS.ODP],
  groups: [
    [
      {
        id: 'data-management',
        category: PRODUCTS.dataManagement,
        displayName: 'Data Management',
        url: '/app/odp/data-management',
        valid: ({ features }) => get(features, MINI_ODP_ACCESS, false),
      },
      // {
      //   id: 'metric-builder',
      //   category: PRODUCTS.metricBuilder,
      //   displayName: 'Metric Builder',
      //   url: '/app/odp/metric-builder',
      //   valid: ({ features }) => get(features, MINI_ODP_ACCESS, false),
      // },
    ],
  ],
};

const ODP = () => {
  return <Menu config={config} />;
};

export default ODP;
