import React from 'react';
import classnames from 'classnames';
import { useShowMenu } from '@subApps/navApp/hooks/useGlobal';
import Button, { StyledMenuOutlined } from './styled';

const MenuButton = () => {
  const { data: showMenu, onStateChange: setShowMenu } = useShowMenu();
  return (
    <Button
      id="menu-toggle"
      className={classnames({ active: showMenu })}
      onClick={() => setShowMenu(!showMenu)}
    >
      <StyledMenuOutlined />
    </Button>
  );
};

export default MenuButton;
