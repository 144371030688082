import useSWR from 'swr';
import { pulse5Fetcher } from '@subApps/navApp/apis/request';

export default () => {
  const { data, error } = useSWR('/feature_flags', pulse5Fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    data,
    error,
    loading: !data,
  };
};
