import React from 'react';
import { Space } from 'antd';
import { isSIOnly } from '@utils/cookie';
import UserButton from './UserButton';
import { Mid } from './styled';

function MidSection() {
  const siOnly = isSIOnly();

  return (
    <Mid>
      <Space>
        {!siOnly && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <UserButton />
          </div>
        )}
      </Space>
    </Mid>
  );
}

export default MidSection;
