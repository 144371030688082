import React from 'react';
import { get } from 'lodash';
import { pulse4Fetcher } from '@subApps/navApp/apis/request';
import { useUser, userProfiles } from '@subApps/navApp/hooks/useData';
import { LoadingIcon } from '@subApps/common/components/Icons';
import { Select, Space } from 'antd';
import { LoadingCustomerName } from './styled';
import formatUserDisplay from './formatUserDisplay';
import Toggle from './Toggle';

const UserButton = () => {
  const [loadingSwitch, setLoadingSwitch] = React.useState<boolean>(false);
  const { data: user, loading: loadingUser } = useUser();
  const { data: profilesData, loading: loadingProfiles } = userProfiles();
  const [visible, setVisible] = React.useState(true);

  const items = React.useMemo(
    () =>
      loadingProfiles
        ? []
        : (profilesData || [])
            .sort((p1, p2) =>
              p1.customer.name.toLocaleLowerCase() < p2.customer.name.toLocaleLowerCase() ? -1 : 1,
            )
            .map(profile => ({
              value: profile.id.toString(),
              label: `${profile.customer.name}_${profile.role}`,
              display: profile.customer.name,
            })),
    [loadingProfiles],
  );

  const handleChange = React.useCallback(async value => {
    setLoadingSwitch(true);
    await pulse4Fetcher.POST(
      '/profile/switch',
      {
        account: value,
      },
      {
        headers: {
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      },
    );
    window.location.href = '/app';
    setLoadingSwitch(false);
  }, []);

  const renderCustomerName = () => {
    if (loadingUser || loadingProfiles) {
      return <LoadingIcon />;
    }
    if (loadingSwitch) {
      return (
        <span>
          <LoadingIcon />
          <LoadingCustomerName>{formatUserDisplay(user!)}</LoadingCustomerName>
        </span>
      );
    }
    return formatUserDisplay(user!);
  };

  return (
    <Space>
      {visible && (
        <>
          <Select
            options={items}
            onChange={handleChange}
            disabled={loadingUser || loadingProfiles || loadingSwitch}
            style={{ minWidth: 200 }}
            dropdownStyle={{ minWidth: 250 }}
            optionFilterProp="label"
            optionLabelProp="display"
            value={user ? user.profileId : ''}
            showSearch
          />
          <div>{renderCustomerName()}</div>
        </>
      )}
      {get(user, 'self.username', '').endsWith('conviva.com') && (
        <Toggle visible={visible} setVisible={setVisible} />
      )}
    </Space>
  );
};

export default React.memo(UserButton);
