import { get } from 'lodash';
import { PRODUCT_URL_PREFIX, PRODUCTS } from '@constants/products';
import { usePulse5Features } from '@subApps/navApp/hooks/useData';
import User from '../useUser/user.model';
import { useProduct } from '../../useGlobal';
import userProfiles from '../userProfiles';
import useUser from '../useUser';
import useNav from '../useNav';
import useLandingPages from '../useLandingPages';
import useDefaultApp from '../useDefaultApp';

const setDefaultProduct = (app, setProduct) => {
  switch (app) {
    case 'ei':
      setProduct(PRODUCTS.EI);
      break;
    case 'ai':
      setProduct(PRODUCTS.AI);
      break;
    case 'app':
      setProduct(PRODUCTS.APP);
      break;
    case 'precision':
      setProduct(PRODUCTS.precision);
      break;
    case 'p4':
      setProduct(PRODUCTS.EI);
      break;
    case 'vi':
      setProduct(PRODUCTS.VI);
      break;
    case 'si':
      setProduct(PRODUCTS.SI);
      break;
    case 'ci':
      setProduct(PRODUCTS.CI);
      break;
    case 'advi':
      setProduct(PRODUCTS.ADVI);
      break;
    default:
      setProduct(PRODUCTS.EI);
  }
};

export default (setGlobalState: (state: Record<string, any>) => void) => {
  const { loading: loadingProfiles, data: profiles } = userProfiles();
  const { loading: loadingUser, data: user } = useUser();
  const { loading: loadingNav, data: navData } = useNav();
  const { loading: loadingApp, data: defaultApp } = useDefaultApp();
  const { loading: loadingLandingPages, data: landingPages } = useLandingPages();
  const { loading: loadingFeatures, data: features } = usePulse5Features();
  const { onStateChange: setProduct } = useProduct();

  if (
    loadingProfiles ||
    loadingUser ||
    loadingNav ||
    loadingApp ||
    loadingLandingPages ||
    loadingFeatures
  ) {
    return true;
  }

  setGlobalState({
    nav: navData,
    landingPages,
    user,
    features,
    profiles,
  });

  if (user!.isCustomerService() || User.isViewerOnly(navData)) {
    setProduct(PRODUCTS.EI);

    // allow access to p5 VM
    if (window.location.pathname.includes('/app/experience-insights/dashboard/viewer-module')) {
      return false;
    }
    window.history.replaceState(null, '', '/app/experience-insights/dashboard/viewer-module');
    return false;
  }

  if (user!.isDeveloper()) {
    setProduct(PRODUCTS.EI);
    window.history.replaceState(null, 'My Profile', `/app/pulse/settings/personal_info`);
    return false;
  }

  // Complete URL, don't init
  if (!/^\/app\/?$/.test(window.location.pathname)) {
    return false;
  }

  // Not a complete URL, check default app and default landing page.
  const landing = get(defaultApp, 'landing', null);

  if (get(defaultApp, 'app', null)) {
    setDefaultProduct(defaultApp.app, setProduct);
  }

  // Jump to default landing page
  if (landing?.path) {
    const { path } = landing;

    if (/^\/app\/?/.test(path)) {
      window.history.replaceState(null, landing.name || '', path);
    } else {
      window.history.replaceState(null, landing.name || '', `/app${path}`);
    }

    return false;
  }

  // No default landing page, just jump to default app
  if (defaultApp) {
    switch (defaultApp.app) {
      case 'ei':
        window.history.replaceState(
          null,
          '',
          `${PRODUCT_URL_PREFIX[PRODUCTS.EI]}/dashboard/trends`,
        );
        break;
      case 'ai':
        window.history.replaceState(
          null,
          '',
          `${PRODUCT_URL_PREFIX[PRODUCTS.AI]}/dashboard/trends`,
        );
        break;
      case 'app':
        window.history.replaceState(
          null,
          '',
          `${PRODUCT_URL_PREFIX[PRODUCTS.APP]}/dashboard/app-experience`,
        );
        break;
      case 'precision':
        window.history.replaceState(null, 'precision', '/app/precision/precision_policy');
        break;
      case 'p4':
        window.history.replaceState(
          null,
          '',
          `${PRODUCT_URL_PREFIX[PRODUCTS.EI]}/dashboard/trends`,
        );
        break;
      case 'vi': {
        window.history.replaceState(null, '', PRODUCT_URL_PREFIX.VI);
        break;
      }
      case 'si': {
        window.history.replaceState(null, '', PRODUCT_URL_PREFIX.SI);
        break;
      }
      case 'ci': {
        window.history.replaceState(null, '', PRODUCT_URL_PREFIX.CI);
        break;
      }
      case 'advi': {
        window.history.replaceState(null, '', PRODUCT_URL_PREFIX.ADVI);
        break;
      }
      default:
        window.history.replaceState(
          null,
          '',
          `${PRODUCT_URL_PREFIX[PRODUCTS.EI]}/dashboard/trends`,
        );
    }

    return false;
  }

  // No default app and landing page, jump to personal_info and set current product to EI
  setProduct(PRODUCTS.EI);
  window.history.replaceState(null, '', '/app/pulse/settings/personal_info');

  return false;
};
