import { isFunction } from 'lodash';
import { P4Nav } from '@subApps/navApp/hooks/useData/useNav/typed';
import { Route } from '../types';

export default (routes: Route[], nav: P4Nav | undefined = undefined, features: any = undefined) => {
  const handleApply = ({ key }) => {
    const route = routes.find(_route => _route.id === key);
    if (!route) {
      return;
    }
    if (isFunction(route.url)) {
      route.url(route);
      return;
    }

    if (!route.url && route.menu) {
      const menu = route.menu.find(m => m.valid({ nav, features }));
      if (menu) {
        route.url = menu.url as string;
      }
    }

    const displayName = isFunction(route.displayName)
      ? route.displayName(route)
      : route.displayName;
    if (route.global) {
      window.location.href = route.url as string;
    } else {
      window.history.pushState(null, displayName, route.url as string);
    }
  };
  return handleApply;
};
