import { isFunction } from 'lodash';
import { Route } from '../types';

export default (routes: Route[]) =>
  routes.reduce((_items: Array<{ key: string; label: string }>, route) => {
    const displayValue = isFunction(route.displayName)
      ? route.displayName(route)
      : route.displayName;
    const shouldDisplay = isFunction(route.display) ? route.display(route) : route.display;
    if (shouldDisplay === true || shouldDisplay === undefined) {
      _items.push({
        key: route.id,
        label: displayValue,
      });
    }
    return _items;
  }, []);
