import React from 'react';
import { LoadingIcon } from '@subApps/common/components/Icons';
import { useProduct } from '@subApps/navApp/hooks/useGlobal';
import { useAlerts, useAIAlertsSummary, usePulse5Features } from '@subApps/navApp/hooks/useData';
import { ManualAlertEvent } from '@subApps/navApp/hooks/useData/useAlerts/typed';
import { sortAlertsSummary } from '@subApps/navApp/utils';
import { PRODUCTS } from '@constants/products';
import { useGetMergedMetrics } from '@subApps/navApp/hooks/useData/useFetchMetricDefinition/useGetMergedMetrics';
import { AI_ALERTS_PULSE5 } from '@subApps/navApp/hooks/useData/usePulse5Features/featureNames';
import AlertItem from './AlertItem';
import AIAlertItem from './AIAlertItem';
import { OverlayWrapper, MsgWrapper, OverlayTitle, AlertsWrapper, StyledDivider } from './styled';

const EMPTY_MASSAGE = 'There are no alerts.';
const MAX_ITEM_COUNT = 3;

const AlertBellOverlay = () => {
  const { data: product } = useProduct();
  // const { data: user } = useUser();
  const { data: features } = usePulse5Features();

  const isAI = product === PRODUCTS.AI;
  const AIAlertEnabled = features[AI_ALERTS_PULSE5] || false;

  const alertType = isAI ? 2 : 0;
  const aiAlertType = isAI ? 'AI' : 'EI';
  const aiAlertProductType = isAI ? 'AD_ALERT' : 'VIDEO_ALERT';

  const { data: manualAlertEvents, loading: manualAlertLoading } = useAlerts({
    type: alertType,
    limit: MAX_ITEM_COUNT,
  });
  const { result: metricsMeta, loading: metricMetaLoading } = useGetMergedMetrics({
    type: aiAlertType.toLowerCase(),
  });
  const { data: AiAlertsSummaryData, loading: aiAlertLoading } = useAIAlertsSummary({
    type: aiAlertType.toLowerCase(),
    limit: MAX_ITEM_COUNT,
  });

  // filter out the ECO1 alert data in video or ad
  const alertsSummary = (AiAlertsSummaryData?.alertsSummary || []).filter(alertItem => {
    return alertItem.alertType === aiAlertProductType;
  });

  const manualAlertsData: ManualAlertEvent[] = manualAlertEvents || [];

  const isEmptyManualAlert = !manualAlertLoading && manualAlertsData.length === 0;
  const isEmptyAIAlert = !aiAlertLoading && alertsSummary.length === 0;
  const sortedAlertsSummary = sortAlertsSummary(alertsSummary);

  const AIAlertSection = AIAlertEnabled ? (
    <>
      <OverlayTitle>
        <span>Recent AI Alerts</span>
        {aiAlertLoading && <LoadingIcon />}
      </OverlayTitle>
      {isEmptyAIAlert && <MsgWrapper>{EMPTY_MASSAGE}</MsgWrapper>}
      {metricMetaLoading ? <LoadingIcon style={{ margin: '8px auto', display: 'block' }} /> : null}
      {!aiAlertLoading && !isEmptyAIAlert && (
        <AlertsWrapper>
          {sortedAlertsSummary.slice(0, 3).map(event => (
            <AIAlertItem key={event.alertId} event={event} isAI={isAI} metricsMeta={metricsMeta} />
          ))}
        </AlertsWrapper>
      )}
      <StyledDivider />
    </>
  ) : null;

  const manualAlertSection = (
    <>
      <OverlayTitle>
        <span>Recent Manual Alerts</span>
        {manualAlertLoading && <LoadingIcon />}
      </OverlayTitle>
      {isEmptyManualAlert && <MsgWrapper>{EMPTY_MASSAGE}</MsgWrapper>}
      {metricMetaLoading ? <LoadingIcon style={{ margin: '8px auto', display: 'block' }} /> : null}
      {!metricMetaLoading && !manualAlertLoading && !isEmptyManualAlert && (
        <AlertsWrapper>
          {manualAlertsData.map(event => (
            <AlertItem key={event.id} event={event} isAI={isAI} metricsMeta={metricsMeta} />
          ))}
        </AlertsWrapper>
      )}
    </>
  );

  return (
    <OverlayWrapper>
      {AIAlertSection}
      {manualAlertSection}
    </OverlayWrapper>
  );
};

export default React.memo(AlertBellOverlay);
