export enum ENDPOINTS {
  pulse4 = 'pulse4',
  pulse5 = 'pulse5',
  si = 'si',
  vi = 'vi',
}

export const ENDPOINT_DOMAINS: {
  [key in ENDPOINTS]: string;
} = JSON.parse(process.env.ENDPOINT_DOMAINS || '{}');
