import styled from 'styled-components';
import { colorMap } from '@utils/colorMap';

export const WrapperSubContent = styled.div<{
  expanded?: boolean;
  transition?: boolean;
  height?: number;
}>`
  transition: 0.3s height ease-in-out;
  visibility: ${props => (props.transition || props.expanded ? 'visible' : 'hidden')};
  height: ${props => (props.expanded ? `${props.height || 0}px` : '0')};
  overflow: hidden;
`;

export const WrapperItem = styled.a`
  display: flex;
  position: relative;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  height: 39px;
  line-height: 39px;
  color: ${() => colorMap.secondaryOneHover};
  padding: 0 20px;
  transition: 0.2s all ease-in-out;
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: ${() => colorMap.bgHighlight};
    color: ${() => colorMap.secondaryOneHover};
  }
  &:active {
    background-color: ${() => colorMap.highlight};
    color: ${() => colorMap.secondaryOneHover};
  }
  &::before {
    content: ' ';
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 0;
    height: 1px;
    border-bottom: 1px solid ${() => colorMap.lineSeparator};
  }
  &.active {
    background-color: ${() => colorMap.highlight};
    color: ${() => colorMap.secondaryOneHover};
  }

  ${WrapperSubContent} & {
    padding-left: 40px;

    &:not(:last-child)::before {
      border: none;
    }
  }
`;
