import React from 'react';
import { withErrorBoundary } from '@subApps/common/components';
import SettingPanel from './SettingPanel';

export interface SettingProps {
  compact?: boolean;
}

const Setting = (props: SettingProps) => {
  const { compact = false } = props;

  return <SettingPanel compact={compact} />;
};

export default React.memo(withErrorBoundary(Setting));
