import styled from 'styled-components';

export const LoadingCustomerName = styled.span`
  margin-left: 5px;
`;

export const ToggleWrapper = styled.span`
  cursor: pointer;
  display: flex;
  align-self: center;
  padding: 6px;
`;
