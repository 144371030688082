import React from 'react';
import { PRODUCT_URL_PREFIX } from '@constants/products';
import {
  AI_ALERT_MAPPING,
  MULTILPIER,
} from '@subApps/navApp/hooks/useData/useAIAlertsSummary/metrics';
import humanize from '@utils/humanize';
import { usePulse5Features } from '@subApps/navApp/hooks/useData';
import { get } from 'lodash';
import { AI_ALERTS_PULSE5 } from '@subApps/navApp/hooks/useData/usePulse5Features/featureNames';
import { AlertsSummary } from '@subApps/navApp/hooks/useData/useAIAlertsSummary/typed';

interface Props {
  event: AlertsSummary;
  isAI?: boolean;
  isAPP?: boolean;
  metricsMeta: object;
}

const DELETE_METRIC_NAME = 'DELETED METRIC';

const formatCondition = ({
  event,
  metricsMeta,
}: {
  event: AlertsSummary;
  metricsMeta: any;
  isAI?: boolean;
  isAPP?: boolean;
}) => {
  const { metricName, metricValue } = event;
  const metricNameStr = AI_ALERT_MAPPING[metricName] || metricName;
  const metric = metricsMeta?.[metricNameStr];
  const unit = metric?.unit || '';
  const multiplier = MULTILPIER[metricName] || 1;
  // This is a fallback logic, if the metric doesn't occur in the meta
  // we show DELETED METRIC here, and customer can't drill down to the diagnostic page
  return `${metric?.displayName || DELETE_METRIC_NAME} = ${humanize(
    metricValue * multiplier,
    unit,
  )} ${unit}`;
};

const getHref = ({ isAI, isAPP, cause, alertId, pulse5AIAlertsEnabled = false }: any) => {
  if (pulse5AIAlertsEnabled) {
    const alertPath = (() => {
      if (isAPP) return 'pre-app-diagnostics';
      if (isAI) return 'pre-ad-diagnostics';
      return 'pre-diagnostics';
    })();
    const urlPrefix = isAPP ? PRODUCT_URL_PREFIX.APP : PRODUCT_URL_PREFIX.EI;
    const link = `${urlPrefix}/dashboard/ai-alerts/${alertPath}/${alertId}/Alert- ${cause}`;
    return link;
  }
  return `${PRODUCT_URL_PREFIX.Pulse}/alerts4/${isAI ? 'adi/' : ''}${alertId}`;
};

const AIAlertItem = ({ event, isAI, isAPP, metricsMeta }: Props) => {
  const { alertId, rootCauseDisplayLabel = '' } = event;
  const { data: features } = usePulse5Features();
  const pulse5AIAlertsEnabled = get(features, AI_ALERTS_PULSE5, false);

  const causeStr = rootCauseDisplayLabel.replace(/&&&/g, ' and ');

  const href = getHref({
    isAPP,
    isAI,
    cause: causeStr,
    alertId,
    pulse5AIAlertsEnabled,
  });

  const condition = formatCondition({ event, metricsMeta });

  const isDeletedMetric = condition.includes(DELETE_METRIC_NAME);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    // customer can't drill down to the diagnostic page by clicking the deleted or unaccessible metrics
    if (!isDeletedMetric) {
      window.history.pushState(null, alertId.toString(), href);
    }
  };

  return (
    <a
      href={href}
      onClick={handleClick}
      style={{
        cursor: isDeletedMetric ? 'not-allowed' : 'pointer',
      }}
    >
      <span className="left" title={causeStr as string}>
        {causeStr}
      </span>
      <span className="right" title={condition}>
        {condition}
      </span>
    </a>
  );
};

export default AIAlertItem;
