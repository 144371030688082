import React from 'react';
import { useProduct } from '@subApps/navApp/hooks/useGlobal';
import { MENU_TYPES, SubMenu } from '@constants/subMenu';
import { getSubMenu } from '@utils/url';
import PRODUCTS from '@constants/products';

const useSubMenu = (): [[MENU_TYPES, SubMenu] | null, () => void] => {
  const currentMenuRef = React.useRef<[MENU_TYPES, SubMenu] | null>(null);
  const renderer = React.useState<null | {}>(null)[1];
  const { data: product } = useProduct();

  const setSubMenu = React.useCallback(() => {
    currentMenuRef.current = getSubMenu(product as PRODUCTS);
    renderer({});
  }, [product]);

  React.useEffect(() => {
    setSubMenu();
  }, []);

  React.useEffect(() => {
    window.addEventListener('popstate', setSubMenu);
    window.addEventListener('pushState', setSubMenu);
    window.addEventListener('replaceState', setSubMenu);
    return () => {
      window.removeEventListener('popstate', setSubMenu);
      window.removeEventListener('pushState', setSubMenu);
      window.removeEventListener('replaceState', setSubMenu);
    };
  }, [setSubMenu]);

  return [currentMenuRef.current, setSubMenu];
};

export default useSubMenu;
