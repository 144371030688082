import { useCallback, useEffect } from 'react';
import {
  trackPageView,
  trackCustomEvent,
  setUserId,
  setCustomTags,
} from '@convivainc/conviva-js-appanalytics';
import { useUser } from '../useData';

const PAGE_START = {
  fetchStart: performance.timing.fetchStart,
  url: location.href, // page url from initial load
};

const APP_EVENT = 'trackCustomEvent';

const TOTAL_PAGE_LOAD_TIME = 'Total page load time';

export function trackEvent({ name, data }) {
  trackCustomEvent({
    name,
    data,
  });
}

/*
case1: from tracked page, -> call trackCustomEvent
case2: from untracked page navigate to tracked page, -> don't call trackCustomEvent, clean up fetchStart
case3: from tracked page navigate to untracked page but doesn't call customEventHandler,
       then navigate to tracked page, -> don't call trackCustomEvent, clean up fetchStart
 */

window.addEventListener('popstate', () => {
  // clean up fetchStart if navigate to untracked page
  if (location.href !== PAGE_START.url) {
    PAGE_START.fetchStart = 0;
  }
});

function perfEventHandler(detail) {
  if (detail?.data?.url === PAGE_START.url && PAGE_START.fetchStart) {
    const loadTime = Date.now() - PAGE_START.fetchStart;

    detail.data = {
      ...(detail.data || {}),
      fetchStart: PAGE_START.fetchStart,
      loadTime,
    };
    delete detail.data.url;

    trackCustomEvent(detail);
  }

  PAGE_START.fetchStart = 0; // only upload once
}

function useSensor() {
  const { data: user } = useUser();

  useEffect(() => {
    if (user && user.username) {
      setUserId(`${user.customerName}~${user.username}`);
      setCustomTags({ customerName: user.customerName, customerId: user.customerId });
      trackPageView();
    }
  }, [JSON.stringify(user)]);

  const customEventHandler = useCallback(
    ({ detail }: any) => {
      if (detail.name === TOTAL_PAGE_LOAD_TIME) {
        perfEventHandler(detail);
      } else {
        trackCustomEvent(detail);
      }
    },
    [trackCustomEvent],
  );

  // handle custom app events
  useEffect(() => {
    document.addEventListener(APP_EVENT, customEventHandler);
    return () => document.removeEventListener(APP_EVENT, customEventHandler);
  }, []);
}

export default useSensor;
