import React, { useMemo } from 'react';
import { PRODUCTS, PRODUCT_DISPLAY_NAMES_NEW_NAV } from '@constants/products';
import { useSIMenuList } from '@subApps/navApp/hooks/useData';
import SocialIcon from '@resources/icons/product/Social.svg';
import { useSIStrictPermission } from '@subApps/navApp/hooks/useData/useSIMenuList';
import { Menu } from '../../components/Menu';
import type { MenuProps } from '../../components/Menu/type';
import { normalizeMenu } from '../helper';

const config = {
  product: PRODUCTS.SI,
  icon: <SocialIcon />,
  displayName: PRODUCT_DISPLAY_NAMES_NEW_NAV[PRODUCTS.SI],
  groups: [],
};

const Social = () => {
  const { loading: authLoading, permission } = useSIStrictPermission();
  const { data: menuList, loading } = useSIMenuList(permission);

  const configWithMenu = useMemo(() => {
    const groups = menuList?.map(menu => normalizeMenu(menu, PRODUCTS.SI));

    return {
      ...config,
      disabled: !permission,
      groups: [groups].filter(Boolean),
    } as MenuProps['config'];
  }, [menuList]);

  return <Menu loading={loading || authLoading} config={configWithMenu} />;
};

export default Social;
