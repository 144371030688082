import React from 'react';
import { Tabs } from 'antd';
import { useSubMenu } from '@subApps/navApp/hooks/common';
import { Wrapper, TabWrapper, Title } from './styled';

const Header = () => {
  const [subMenu] = useSubMenu();

  const cachedPayload = React.useMemo(
    () =>
      subMenu
        ? [
            {
              key: 'sub-menu',
              label: subMenu[1].parentName,
              selected: true,
            },
          ]
        : null,
    [subMenu],
  );
  if (!cachedPayload || !subMenu) {
    return null;
  }
  const [, { parentName }] = subMenu;
  return (
    <Wrapper>
      <TabWrapper>
        <Tabs defaultActiveKey="sub-menu" items={cachedPayload} />
      </TabWrapper>
      <Title>{parentName}</Title>
    </Wrapper>
  );
};

export default Header;
