export const colorMap = {
  backgroundColor: '#ffffff',
  primaryNormal: '#0046FF',
  primaryHover: '#002a97',
  primaryDisabled: 'rgba(0,70,255,0.4)',
  secondaryOneNormal: '#686868',
  secondaryOneHover: '#4A4A4A',
  secondaryOneDisabled: 'rgba(104,104,104,0.4)',
  secondaryBackDisabled: '#F1F1F1',
  bgHighlight: '#E9F3FD',
  highlight: '#e9f3fd',
  primarySelected: '#0035BF',
  lineSeparator: '#dadada',
  positiveChange: '#00A150',
  negativeChange: '#D0021B',
  tooltip: '#F2F2F2',
  boxShadow: 'rgba(0,0,0,0.2)',
  tabNormal: '#F6F6F6',
  tabSelected: '#1556FF',
  data: '#000000',
  text: '#000',
  grayText: '#4A4A4A',
  keyword: '#054AFF',
  borderOne: '#9B9B9B',
  scopeAlpha: 0.1,
  tileGoodBorder: '#DADADA',
  tileGoodFill: '#EAEAEA',
  tileWarningBorder: '#F99838',
  tileWarningFill: 'rgba(255,147,0,0.4)',
  tileCriticalBorder: '#D0021B',
  tileCriticalFillNormal: 'rgba(236,15,15,0.6)',
  tileCriticalFillHover: 'rgba(236, 15, 15, 0.8)',
  white: '#FFFFFF',
  util: '#1F1F1F',
  sorter: '#4A4A4A',
  warningDescription: '#FFEAE4',
  dropdownBackground: '#fff',
  hoverColor: '#4A4A4A',
  subTabBackground: '#fff',
};
