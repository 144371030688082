import React from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Space, Button, Popover, Divider } from 'antd';
import { get } from 'lodash';
import { PRODUCTS } from '@constants/products';
import { useProduct } from '@subApps/navApp/hooks/useGlobal';
import { usePulse5Features } from '@subApps/navApp/hooks/useData';
import { isSIOnly } from '@utils/cookie';
import { AUTO_INSIGHTS } from '@subApps/navApp/hooks/useData/usePulse5Features/featureNames';
import AutomaticInsights from './AutomaticInsights';
import MidSection from './MidSection';
import ThemeSwitch from './ThemeSwitch';
import AlertBell from './AlertBell';
import Help from './Help';
import Setting from './Setting';

const CompactSection = () => {
  const { data: product } = useProduct();
  const { data: features } = usePulse5Features();
  const siOnly = isSIOnly();

  // For alpha, do not show help pages for app manager 2.0
  const showAlerts = [PRODUCTS.EI, PRODUCTS.AI, PRODUCTS.APP, PRODUCTS.pulse4].includes(
    product as PRODUCTS,
  );
  const autoInsightsEnabled = get(features, AUTO_INSIGHTS, false);

  const content = (
    <div>
      <MidSection />
      <Divider style={{ margin: '15px 0' }} />
      <div>
        <Help compact />
      </div>
      <div>
        <Setting compact />
      </div>
      {!siOnly && (
        <>
          <Divider style={{ margin: '15px 0' }} />
          <ThemeSwitch />
        </>
      )}
    </div>
  );

  return (
    <Space>
      {autoInsightsEnabled && <AutomaticInsights compact />}
      {showAlerts && <AlertBell />}
      <Popover content={content} trigger="click" placement="bottomRight">
        <Button type="text" icon={<MoreOutlined />} />
      </Popover>
    </Space>
  );
};

export default React.memo(CompactSection);
