import React from 'react';
import { get } from 'lodash';
import {
  PRODUCT_DISPLAY_NAMES,
  PRODUCT_URL_PREFIX,
  PRODUCTS,
  PRODUCT_DISPLAY_NAMES_NEW_NAV,
} from '@constants/products';
import Tool from '@resources/icons/product/Tool.svg';
import { ECO_TRENDS } from '@subApps/navApp/hooks/useData/usePulse5Features/featureNames';
import { Menu } from '../../components/Menu';

const PRODUCT_DOMAIN: {
  [key in PRODUCTS]: string;
} = JSON.parse(process.env.PRODUCT_DOMAIN || '{}');

const config = {
  product: PRODUCTS.Tool,
  icon: <Tool />,
  displayName: PRODUCT_DISPLAY_NAMES_NEW_NAV[PRODUCTS.Tool],
  groups: [
    [
      {
        id: 'VIDEO_TOOL',
        category: PRODUCTS.Tool,
        displayName: 'Video',
        type: 'group',
        children: [
          {
            id: PRODUCTS.appManager,
            category: PRODUCTS.Tool,
            displayName: PRODUCT_DISPLAY_NAMES[PRODUCTS.appManager],
            valid: ({ nav }) => nav?.application?.some(m => m.name === 'Application Manager'),
            url: PRODUCT_URL_PREFIX[PRODUCTS.appManager],
          },
          {
            id: PRODUCTS.touchstone,
            category: PRODUCTS.Tool,
            displayName: PRODUCT_DISPLAY_NAMES[PRODUCTS.touchstone],
            valid: ({ nav }) => nav?.application?.some(m => m.name === 'Touchstone'),
            url: PRODUCT_DOMAIN[PRODUCTS.touchstone],
            target: '_blank',
          },
        ],
      },
    ],

    [
      {
        id: 'ECO_TOOL',
        category: PRODUCTS.Tool,
        displayName: 'ECO',
        type: 'group',
        children: [
          {
            id: PRODUCTS.ecoAppManager,
            category: PRODUCTS.Tool,
            displayName: PRODUCT_DISPLAY_NAMES[PRODUCTS.ecoAppManager],
            valid: ({ features }) => get(features, ECO_TRENDS, false),
            url: PRODUCT_URL_PREFIX[PRODUCTS.ecoAppManager],
          },
          {
            id: 'data-modeling',
            category: PRODUCTS.ACTIVATION,
            displayName: 'Activation',
            url: '/app/activation/home',
            valid: ({ features }) => get(features, ECO_TRENDS, false),
          },
        ],
      },
    ],
    [
      {
        id: 'AUDIENCE',
        category: PRODUCTS.Tool,
        displayName: 'Audience',
        type: 'group',
        children: [
          {
            id: PRODUCTS.metadataManager,
            category: PRODUCTS.Tool,
            displayName: PRODUCT_DISPLAY_NAMES[PRODUCTS.metadataManager],
            valid: ({ nav }) => nav?.application?.some(m => m.link === '/app_switch/mds'),
            url: PRODUCT_URL_PREFIX[PRODUCTS.metadataManager],
          },
        ],
      },
    ],
  ],
};

const Integration = () => <Menu config={config} />;

export default Integration;
