import User from '@subApps/navApp/hooks/useData/useUser/user.model';

const TZNAME_TO_OFFSET = {
  utc: 'UTC-00:00',
  gmt: 'UTC-00:00',
  cvt: 'UTC-01:00',
  gst: 'UTC-02:00',
  brt: 'UTC-03:00',
  edt: 'UTC-04:00',
  est: 'UTC-05:00',
  cdt: 'UTC-05:00',
  cst: 'UTC-06:00',
  mdt: 'UTC-06:00',
  mst: 'UTC-07:00',
  pdt: 'UTC-07:00',
  pst: 'UTC-08:00',
  akdt: 'UTC-08:00',
  akst: 'UTC-09:00',
  hadt: 'UTC-09:00',
  hast: 'UTC-10:00',
  sst: 'UTC-11:00',
  cet: 'UTC+01:00',
  bst: 'UTC+01:00',
  cest: 'UTC+02:00',
  eest: 'UTC+03:00',
  mut: 'UTC+04:00',
  pkt: 'UTC+05:00',
  ist: 'UTC+05:30',
  bdt: 'UTC+06:00',
  tha: 'UTC+07:00',
  hkt: 'UTC+08:00',
  jst: 'UTC+09:00',
  aest: 'UTC+10:00',
  magt: 'UTC+11:00',
  fjt: 'UTC+12:00',
  nzst: 'UTC+12:00',
  nzdt: 'UTC+13:00',
};

export default (user: User) => {
  const tzLower = user.timezone.toLowerCase();
  return user ? `(${TZNAME_TO_OFFSET[tzLower] || user.timezone})` : '';
};
