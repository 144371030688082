import React from 'react';
import { PRODUCTS, PRODUCT_DISPLAY_NAMES_NEW_NAV, PRODUCT_URL_PREFIX } from '@constants/products';
import { useCAMenuList, useNav } from '@subApps/navApp/hooks/useData';
import { useProduct, useShowMenu } from '@subApps/navApp/hooks/useGlobal';
import AudienceIcon from '@resources/icons/product/Audience.svg';
import { useSIStrictPermission } from '@subApps/navApp/hooks/useData/useSIMenuList';
import { normalizeMenu } from '../helper';
import { Menu } from '../../components/Menu';
import type { MenuProps, SubMenuItem } from '../../components/Menu/type';
import { NavMenuContext } from '../../NavMenuContext';

const config = {
  product: PRODUCTS.VI,
  icon: <AudienceIcon />,
  displayName: PRODUCT_DISPLAY_NAMES_NEW_NAV[PRODUCTS.VI],
  groups: [],
};

const Audience = () => {
  const { data: nav } = useNav();
  const { onStateChange: setShowMenu } = useShowMenu();
  const { onStateChange: setProduct } = useProduct();
  const { setSelectedKeys } = React.useContext(NavMenuContext);

  const CAPermission = nav?.application.some(m => m.link === '/app_switch/ci');
  const { loading: siLoading, permission: SIPermission } = useSIStrictPermission();

  const { data: caMenuList, loading: caLoading, error } = useCAMenuList(CAPermission);

  const configWithMenu = React.useMemo(() => {
    const dashboard =
      CAPermission && SIPermission && (caMenuList || []).length > 0
        ? {
            displayName: 'Dashboard',
            url: PRODUCT_URL_PREFIX.VI,
          }
        : undefined;
    const menuList = [dashboard, ...(caMenuList || [])].filter(Boolean);
    const groups = menuList?.map(menu => normalizeMenu(menu, PRODUCTS.VI));

    return {
      ...config,
      disabled: !CAPermission || !!error,
      groups: [groups].filter(Boolean),
    } as MenuProps['config'];
  }, [CAPermission, SIPermission, caMenuList, error]);

  const updateCAPath = (item: SubMenuItem, keyPath: string[]) => {
    const prefix = '/app/viewer-insights';
    const winPath = window.location.pathname;
    if (
      winPath.startsWith(prefix) &&
      !winPath.startsWith(`${prefix}/dashboard`) &&
      !winPath.startsWith(`${prefix}/social`) &&
      item.url !== PRODUCT_URL_PREFIX.VI
    ) {
      window.postMessage(
        {
          type: 'ca_update_path',
          data: item.url,
        },
        '*',
      );
      setShowMenu(false);
      setProduct(item.category);
      setSelectedKeys(keyPath);
      return true;
    }
    return false;
  };

  return (
    <Menu
      loading={caLoading || siLoading}
      config={configWithMenu}
      onEarlyReturnClick={updateCAPath}
    />
  );
};

export default Audience;
