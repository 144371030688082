import useSWR from 'swr';
import { pulse5Fetcher } from '@subApps/navApp/apis/request';
import { Data } from './typed';

export default (query: { from?: string; to?: string; type: string; limit: number }) => {
  /* hook to make IF-Server pulse5 api call */

  if (!query.from || !query.to) {
    const date = new Date();
    query.to = date.toISOString();
    date.setDate(date.getDate() - 1);
    query.from = date.toISOString();
  }

  const { data, error } = useSWR<Data>(
    '/ai-alerts/summary',
    url =>
      pulse5Fetcher(
        `${url}?mode=${query.type}&limit=${query.limit}&from=${query.from}&to=${query.to}`,
      ),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryCount: 3,
    },
  );

  return {
    data,
    error,
    loading: !data,
  };
};
