import React from 'react';
import { Header, Menus } from './components';

const SubMenu = () => {
  return (
    <>
      <Header />
      <Menus />
    </>
  );
};
export default SubMenu;