import React from 'react';

const cacheImage: {
  [key: string]: HTMLImageElement;
} = {};

function emptyDOM<T extends HTMLElement> (element: T) {
  if (!element) {
    return;
  }
  let child = element.firstChild;
  while (child) {
    const originChild = child;
    child = originChild.nextSibling;
    element.removeChild(originChild);
  }
}
const PreviewImage = React.forwardRef<HTMLImageElement, React.ImgHTMLAttributes<HTMLImageElement> & { src: string }>((
  { src },
  ref,
) => {
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    if (!wrapperRef.current) {
      if (ref && typeof ref === 'object') {
        ref.current = null;
      }
      return;
    }
    if (cacheImage[src]) {
      emptyDOM(wrapperRef.current);
      wrapperRef.current.appendChild(cacheImage[src]);
      if (ref && typeof ref === 'object') {
        ref.current = cacheImage[src];
      }
      return;
    }
    const image = new Image();
    image.src = src;
    if (ref && typeof ref === 'object') {
      ref.current = image;
    }
    cacheImage[src] = image;
    emptyDOM(wrapperRef.current);
    wrapperRef.current.appendChild(cacheImage[src]);
    return () => {
      if (wrapperRef.current && cacheImage[src]) {
        if (ref && typeof ref === 'object') {
          ref.current = null;
        }
        emptyDOM(wrapperRef.current);
      }
    }
  }, [wrapperRef.current, src]);
  return (
    <div ref={wrapperRef} />
  );
});

export default PreviewImage;
