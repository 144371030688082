import { P4Nav } from '@subApps/navApp/hooks/useData/useNav/typed';

// From portal/conviva/pulse4/libs/j2t.py
const PATH_TO_HELP = {
  '/alerts3/': '/help/PLS/Experience-Insights-Alerts_821004.html',
  '/alerts3/events/': '/help/PLS/Viewing-Alert-Events_819538.html',
  '/alerts3/add/': '/help/PLS/Creating-Alerts_819299.html',
  '/alerts4/': '/help/PLS/AI-Alert-Pulse-5_5898287.html',
  '/alerts4/sensitivity/': '/help/PLS/AI-Alert-Sensitivity_1474659.html',
  '/alerts4/forward/': '/help/PLS/Alert-Forwarding_1475686.html',
  '/daily_emails/': '/help/PLS/Daily-Emails_819232.html',
  '/daily_emails/new/': '/help/PLS/Daily-Emails_819232.html',
  '/events/': '/help/PLS/Live-Events_819273.html',
  '/events/new/': '/help/PLS/Live-Events_819273.html',
  '/filters/': '/help/PLS/Managing-Filters_820940.html',
  '/filters/duplicate/': '/help/PLS/820942.html',
  '/filters/new/': '/help/PLS/820942.html',
  '/policies/': '/help/PLS/Policies_819263.html',
  '/policies/instance/': '/help/PLS/Policy-Drill-Down_819515.html',
  '/policies/tracking/': '/help/PLS/Policy-Tracking_819508.html',
  '/reports/1/': '/help/PLS/Quality-Dashboard_820792.html',
  '/reports/55/': '/help/PLS/Quality-Dashboard_820792.html',
  '/reports/10/': '/help/PLS/Video-Start-Saves_819261.html',
  '/reports/12/': '/help/PLS/Audience-Dashboard_820794.html',
  '/reports/13/': '/help/PLS/Top-Content-Dashboard_360481.html',
  '/reports/14/': '/help/PLS/Traffic-Dashboard_820798.html',
  '/reports/24/': '/help/PLS/Traffic-Dashboard_820798.html',
  '/reports/15/': '/help/PLS/Real-Time-Dashboard_820790.html',
  '/reports/54/': '/help/PLS/Real-Time-Dashboard_820790.html',
  '/reports/2/': '/help/PLS/Engagement-Dashboard_820796.html',
  '/reports/25/': 'help/PLS/Account-Overview-Dashboard_5898392.html',
  '/reports/9/': '/help/PLS/Switch-Tracking_819259.html',
  '/reports/diagnostic/': '/help/PLS/Experience-Insights-Diagnostics_820866.html ',
  '/reports/ml/audience/': '/help/PLS/MetricLens-Summary_821221.html',
  '/reports/ml/quality/': '/help/PLS/MetricLens-Summary_821221.html',
  '/reports/mlts/quality/': '/help/PLS/MetricLens-Timeline_821225.html',
  '/reports/mlts/audience/': '/help/PLS/MetricLens-Timeline_821225.html',
  '/reports/mlts/playback/': '/help/PLS/Ad-Experience-MetricLens-Timeline-Dashboard_2031783.html',
  '/reports/mlts/content/': '/help/PLS/MetricLens-Timeline_821225.html',
  '/reports/my/': '/help/PLS/Experience-Insights-Custom-Dashboard_820921.html',
  '/reports/pi/': '/help/PLS/Player-Insight-Dashboard_820802.html',
  '/reports/pi/new': '/help/PLS/Player-Insight-Dashboard_820802.html',
  '/roles/': '/help/PLS/User-Roles_819228.html',
  '/roles/new/': '/help/PLS/User-Roles_819228.html',
  '/settings/adi/parameters/': '/help/PLS/Ad-Account-Setting_3506580.html',
  '/subscribers/': '/help/PLS/Viewers_819267.html',
  '/subscribers/tags/': '/help/PLS/Session-Tags_820870.html',
  '/users/': '/help/PLS/User-Management_5898422.html',
  '/users/new/': '/help/PLS/Users_819226.html',
  '/users/invite/': '/help/PLS/Users_819226.html',
  '/reports/27/': '/help/PLS/Ad-Breaks-Real-Time-Dashboard_820815.html',
  '/reports/28/': '/help/PLS/820817.html',
  '/reports/36/': '/help/PLS/Ad-Experience-Analysis-Dashboard_1475504.html',
  '/reports/ml/pods/': '/help/PLS/820821.html',
  '/alerts3/pods/': '/help/PLS/820827.html',
  '/reports/pods/my/': '/help/PLS/820862.html',
  '/reports/ml/playback/': '/help/PLS/Ad-Experience-MetricLens-Summary-Dashboard_1475509.html',
  '/reports/ml/content/': '/help/PLS/820821.html',
  '/reports/pods_diagnostic/': '/help/PLS/Ad-Breaks-Diagnostics-Dashboard_2031849.html',
  '/reports/ads_diagnostic/': '/help/PLS/Ad-Experience-Diagnostics-Dashboard_820824.html',
  '/alerts3/ads/': '/help/PLS/Ad-Experience-Alerts-Dashboard_1475502.html',
  '/ssd/': '/help/PLS/Session-Source-Data_1474585.html',
  '/pagerduty/': '/help/PLS/PagerDuty-Service-Integrations_1475276.html',
  '/reports/60/': '/help/PLS/Ad-Usage_2032310.html',
  '/reports/37/': '/help/PLS/Ad-Experience-Real-Time-Dashboard_1475511.html',
  'alerts4/adi/sensitivity#AD_AUTOALERTVSF':
    '/help/PLS/Ad-Experience-AI-Alert-Settings_2032060.html',
  'alerts4/manage_subscription/': '/help/PLS/Notification-Channels_5898413.html',
  '/alerts4': '/help/PLS/AI-Alert-Pulse-5_5898287.html',
  'alerts4/adi': '/help/PLS/Ad-Experience-AI-Alerts_2032056.html',
  '/admin/apps/list': 'API-Management_4129047.html',
  '/exec_email': '/help/PLS/Daily-Emails_819232.html',
  '/executive_report': 'Daily-Emails_819232.html',
  '/subscribers/tags': 'Session-Tags_820870.html',
  '/settings/personal_info': 'My-Profile_820399.html',
  '/package': '/help/PLS/Account-Details-Dashboard_5898333.html',
  '/settings/subscriptions_annotations/': 'help/PLS/My-Profile-Subscriptions_5898573.html',
};

/* eslint-disable no-restricted-syntax, camelcase */
const flatten = (menu, nav) => {
  let copy = menu.slice(0);
  if (Array.isArray(nav)) {
    for (const n of nav) {
      if (n.name === 'Dashboards') {
        for (const link of Object.keys(n)) {
          if (link === 'children') {
            copy = copy.concat(n.children);
          } else if (link === 'name') {
            continue;
          } else {
            copy.push({
              ...n[link],
              link,
            });
          }
        }
      } else {
        copy.push(n);
      }
    }
    return copy;
  }
  for (const n of Object.values(nav)) {
    copy = copy.concat(flatten(copy, n));
  }
  return copy;
};

const overWritePulse4ProfilehelpLinks = HELP_MAP => {
  Object.assign(HELP_MAP, {
    '/settings/account_summary/': {
      help: '/learning-center/content/ei_application/basics/settings/my_profile_assignments.htm',
      help_title: 'Accounts Help',
    },
    '/settings/application/': {
      help: '/learning-center/content/ei_application/basics/settings/my_profile_applications.htm',
      help_title: 'Applications Help',
    },
    '/settings/device_info/': {
      help: '/learning-center/content/ei_application/basics/settings/my_profile_devices.htm',
      help_title: 'Devices Help',
    },
    '/settings/personal_info/': {
      help: '/learning-center/content/ei_application/basics/settings/my_profile_personal_info.htm',
      help_title: 'My Profile Help',
    },
    '/settings/subscriptions_annotations/': {
      help: '/learning-center/content/ei_application/basics/settings/my_profile_subscriptions.htm',
      help_title: 'Subscriptions Help',
    },
    '/alerts4/': {
      help: '/learning-center/content/ei_application/dashboards/ai_alerts_dashboard.htm',
      help_title: 'AI Alert Help',
    },
    '/reports/13/': {
      help: '/learning-center/content/ei_application/dashboards/top-content-dashboard_360481.html',
      help_title: 'Top Rising Content Help',
    },
    '/reports/pi/': {
      help:
        '/learning-center/content/ei_application/dashboards/player-insight-dashboard_820802.html',
      help_title: 'Player Insights Help',
    },
    '/reports/pi/new/': {
      help:
        '/learning-center/content/ei_application/dashboards/player-insight-dashboard_820802.html',
      help_title: 'Player Insights Help',
    },
    '/reports/14/': {
      help: '/learning-center/content/ei_application/dashboards/traffic-dashboard_820798.html',
      help_title: 'Traffic Dashboard Help',
    },
    '/device_validation/': {
      help: '/learning-center/content/ei_application/dashboards/device-validation_1474753.html',
      help_title: 'Device Validation Help',
    },
    '/subscribers/': {
      help: '/learning-center/content/ei_application/dashboards/viewers_dashboard.htm',
      help_title: 'Viewer Sessions Help',
    },
    '/package/': {
      help: '/learning-center/content/ei_application/basics/settings/account_overview_db.htm',
      help_title: 'Account Details Help',
    },
    '/reports/25/': {
      help: '/learning-center/content/ei_application/basics/settings/account_overview_db.htm',
      help_title: 'Usage Dashboard Help',
    },
    '/filters/': {
      help: '/learning-center/content/ei_application/basics/filters/filters_821049.html',
      help_title: 'Filter Management Help',
    },
    '/users/': {
      help: '/learning-center/content/ei_application/basics/settings/user_management.htm',
      help_title: 'User Management Help',
    },
    '/subscribers/tags/': {
      help: '/learning-center/content/ei_application/basics/settings/session_tags.htm',
      help_title: 'Session Tags Help',
    },
    '/exec_email/': {
      help: '/learning-center/content/ei_application/basics/settings/executive_emailv1.htm',
      help_title: 'Executive Email v1.0 Help',
    },
    '/alerts4/manage_subscription/': {
      help: '/learning-center/content/ei_application/basics/settings/notification_channels.htm',
      help_title: 'Notification Channels Help',
    },
    '/alerts4/adi/': {
      help:
        '/learning-center/content/ai_application/dashboards/ad-insights-ai-alerts-dashboard.htm',
      help_title: 'AI Alert Help',
    },
    '/ad-insights/alert/manual/summary/': {
      help: '/learning-center/content/ai_application/dashboards/ad-insights-alerts-dashboard.htm',
      help_title: 'Alerts Help',
    },
    '/alerts4/adi/manage_subscription/': {
      help:
        '/learning-center/content/ai_application/basics/settings/ad-insights-notification-channels.htm',
      help_title: 'Notification Channels Help',
    },
    '/ssd/': {
      help: '/learning-center/content/api_developer_center/ssd/ei_ssd/ssd_user_guide.html',
      help_title: 'Session Source Data Help',
    },
  });
};

const getHelpLinks = (
  nav: P4Nav | undefined,
): {
  path: string;
  name: string;
} => {
  const pathname = window.location.pathname || '';
  const urlSearch = window.location.search || '';
  const formatPathname =
    pathname[pathname.length - 1] === '/'
      ? pathname.replace('/app/pulse', '')
      : `${pathname}/`.replace('/app/pulse', '');

  let HELP_MAP = {};

  if (nav) {
    const links = flatten([], nav);
    HELP_MAP = links.reduce((acc, curr) => {
      const { help_title, link, help } = curr;
      if (!link) return curr;
      const p = link.split('?')[0];
      acc[p[p.length - 1] === '/' ? p : `${p}/`] = { help_title, help };
      return acc;
    }, {});
  }

  // profile links comes in a different format from pulse 4.
  // overwriting in apphub with new links. To be removed once we move all the pulse 4 links to pulse 5
  overWritePulse4ProfilehelpLinks(HELP_MAP);

  if (HELP_MAP[formatPathname]) {
    return {
      path: HELP_MAP[formatPathname].help,
      name: HELP_MAP[formatPathname].help_title,
    };
  }

  if (PATH_TO_HELP[formatPathname]) {
    return {
      path: PATH_TO_HELP[formatPathname],
      name: '',
    };
  }

  // saved dashboards - app
  if (/\/app-insights\/dashboard\/saved-dashboards/.test(pathname)) {
    return {
      path: '/learning-center/content/eco/saved_dashboards.htm',
      name: 'Saved Dashboards',
    };
  }
  // ai alerts - app
  if (/\/app-insights\/dashboard\/ai-alerts/.test(pathname)) {
    return {
      path: '/learning-center/content/eco/ai_alerts.html',
      name: 'AI Alerts',
    };
  }

  // trends
  if (/\/ad-insights\/dashboard\/trends/.test(pathname)) {
    return {
      path:
        '/learning-center/content/ai_application/dashboards/ad-insights-ad-experience-dashboard.htm',
      name: 'Ad Experience Dashboard Help',
    };
  }

  if (/\/ad-insights\/dashboard\/saved-dashboards/.test(pathname)) {
    return {
      path: '/learning-center/content/ai_application/dashboards/ad-insights-saved-dashboards.htm',
      name: 'Saved Dashboards Help',
    };
  }

  if (/\/ad-insights\/alert\/manual/.test(pathname)) {
    return {
      path: '/learning-center/content/ai_application/dashboards/ad-insights-alerts-dashboard.htm',
      name: 'Manual Alerts Help',
    };
  }

  if (/\/ad-insights/.test(pathname)) {
    return {
      path: '/help/PLS/New-Pulse-5-Ad-Insights-Quick-Guide_4128957.html',
      name: 'Ad Experience Quick Guide',
    };
  }

  // sensitivity adi is_a4_diagnostics
  if (/\/alerts4\/adi\/sensitivity/.test(pathname)) {
    return {
      path: '/help/PLS/Ad-Experience-AI-Alert-Settings_2032060.html',
      name: '',
    };
  }

  // adi is_a4_diagnostics
  if (/\/alerts4\/adi/.test(pathname)) {
    return {
      path:
        '/learning-center/content/ai_application/dashboards/ad-insights-ai-alerts-dashboard.htm',
      name: 'Auto Diagnostics Help',
    };
  }

  // adi manage_subscription
  if (/\/alerts4\/adi\/manage_subscription/.test(pathname)) {
    return {
      path: '/help/PLS/Ad-Experience-AI-Alert-Email-Subscription_2032058.html',
      name: '',
    };
  }

  // manage_subscription and adi manage_subscription
  if (/\/alerts4\/(adi\/)?manage_subscription/.test(pathname)) {
    return {
      path: '/help/PLS/Ad-Experience-AI-Alert-Email-Subscription_2032058.html',
      name: '',
    };
  }

  // is_a4_diagnostics
  if (/\/alerts4/.test(pathname)) {
    return {
      path: '/help/PLS/AI-Alerts_821307.html#AIAlerts-ViewingAIAlertsDiagnostics',
      name: 'Auto Diagnostics Help',
    };
  }

  // device_validation
  if (/\/device_validation/.test(pathname)) {
    return {
      path: '/learning-center/content/ei_application/dashboards/device-validation_1474753.html',
      name: '',
    };
  }

  // webhooks
  if (/\/webhooks/.test(pathname)) {
    return {
      path: '/help/PLS/Setting-Webhook-Notifications_1474897.html',
      name: '',
    };
  }

  if (/(?:\/precision){2}:{2}resiliency/.test(pathname)) {
    return {
      path:
        '/learning-center/content/precision_application/dashboards/resiliency_tracking_dashboard.htm',
      name: 'Resiliency Dashboard Help',
    };
  }

  if (/(?:\/precision){2}:{2}resiliency_tracking/.test(pathname)) {
    return {
      path:
        '/learning-center/content/precision_application/dashboards/resiliency_tracking_dashboard.htm',
      name: 'Resiliency Dashboard Help',
    };
  }

  if (/\/precision\/policies\/tracking/.test(pathname)) {
    return {
      path:
        '/learning-center/content/precision_application/dashboards/policy_tracking_dashboard.htm',
      name: 'Policies and Policy Tracking Help',
    };
  }

  if (/\/precision/.test(pathname)) {
    return {
      path: '/learning-center/content/precision_application/dashboards/policies_dashboard.htm',
      name: 'Policies Dashboard Help',
    };
  }

  // preset

  if (/\/app-insights\/dashboard\/preset/.test(pathname)) {
    return {
      path: '/learning-center/content/eco/preset.html',
      name: 'Preset Help',
    };
  }

  // real-time

  if (/\/ad-insights\/dashboard\/real-time/.test(pathname)) {
    return {
      path:
        '/learning-center/content/ai_application/dashboards/ad-insights-real-time-dashboard.htm',
      name: 'Real-Time Dashboard Help',
    };
  }

  if (/\/app-insights\/dashboard\/real-time/.test(pathname)) {
    return {
      path: '/learning-center/content/eco/eco_real_time_dashboard.htm',
      name: 'Real-Time Dashboard Help',
    };
  }

  if (/\/dashboard\/real-time/.test(pathname)) {
    return {
      path: '/learning-center/content/ei_application/dashboards/real_time_dashboard.htm',
      name: 'Real-Time Help',
    };
  }

  // metriclens
  if (/\/dashboard\/metriclens/.test(pathname)) {
    return {
      path: '/learning-center/content/ei_application/dashboards/metriclens.htm',
      name: 'MetricLens Help',
    };
  }

  // saved-dashboards
  if (/\/dashboard\/saved-dashboards/.test(pathname)) {
    return {
      path: '/learning-center/content/ei_application/dashboards/saved_dashboards.htm',
      name: 'Saved Dashboards Help',
    };
  }

  // manual-alerts
  if (/\/alert\/manual/.test(pathname)) {
    return {
      path: '/learning-center/content/ei_application/dashboards/manual_alerts.htm',
      name: 'Manual Alerts Help',
    };
  }

  // trends
  if (/\/dashboard\/trends/.test(pathname)) {
    return {
      path: '/learning-center/content/ei_application/dashboards/trends_dashboard.htm',
      name: 'Trends Help',
    };
  }

  // viewers pulse 5
  if (/\/dashboard\/viewer-module/.test(pathname)) {
    return {
      path: '/learning-center/content/ei_application/dashboards/viewer_module_dashboard.htm',
      name: 'Viewers Session Help',
    };
  }

  if (/\/dashboard\/overview/.test(pathname)) {
    return {
      path: '/learning-center/content/ei_application/dashboards/experience_overview.htm',
      name: 'Experience Overview Help',
    };
  }

  if (/\/dashboard\/timeline/.test(pathname)) {
    return {
      path: '/learning-center/content/ei_application/dashboards/viewer_timelines.htm',
      name: 'Viewer Timeline Help',
    };
  }

  if (/\/settings\/personal_info/.test(pathname)) {
    return {
      path: '/learning-center/content/ei_application/basics/settings/my_profile_personal_info.htm',
      name: 'My Profile help',
    };
  }

  if (/\/settings\/subscriptions_annotations/.test(pathname)) {
    return {
      path: '/learning-center/content/ei_application/basics/settings/my_profile_subscriptions.htm',
      name: 'Subscriptions Help',
    };
  }

  if (/\/settings\/device_info/.test(pathname)) {
    return {
      path: '/learning-center/content/ei_application/basics/settings/my_profile_devices.htm',
      name: 'Device help',
    };
  }

  if (/\/profile\/application/.test(pathname)) {
    return {
      path: '/learning-center/content/ei_application/basics/settings/my_profile_applications.htm',
      name: 'Applications help',
    };
  }

  if (pathname.startsWith('/app/executive_report')) {
    return {
      path: '/learning-center/content/ei_application/basics/settings/executive_emailv2.htm',
      name: 'Executive Email v2.0 Help',
    };
  }

  if (pathname.startsWith('app/pulse/exec_email')) {
    return {
      path: '/learning-center/content/ei_application/basics/settings/executive_emailv1.htm',
      name: 'Executive Email v1.0 Help',
    };
  }

  if (pathname.startsWith('/app/experience-insights/dashboard/error-config')) {
    return {
      path:
        '/learning-center/content/ei_application/basics/settings/business-error-configuration.htm',
      name: 'Business Error Configuration Help',
    };
  }

  if (pathname.startsWith('/app/manage/apps')) {
    return {
      path: '/learning-center/content/ei_application/api_mgt/api-management_4129047.htm',
      name: 'API Management Help',
    };
  }

  if (/\/dashboard\/ai-alerts/.test(pathname)) {
    if (/\?config=true$/.test(urlSearch)) {
      return {
        path: '/learning-center/content/ei_application/ea-features/ai_alerts_custom_dimension.html',
        name: 'AI Alerts Custom Dimension Help',
      };
    }

    return {
      path: '/learning-center/content/ei_application/alerts/ai-alerts_821307.html',
      name: 'AI Alerts Help',
    };
  }

  // service integrations
  if (/\/dashboard\/service-integrations/.test(pathname)) {
    return {
      path: '/learning-center/content/ei_application/basics/settings/service_integrations.htm',
      name: 'Service Integration Help',
    };
  }

  /* ECO */
  // ECO Trends
  if (/\/app-insights\/dashboard\/app-experience/.test(pathname)) {
    return {
      path: '/learning-center/content/eco/trends.htm',
      name: 'ECO Trends',
    };
  }
  // ECO paths
  if (/\/app\/app-insights\/dashboard\/paths/.test(pathname || '')) {
    return {
      path: '/learning-center/content/eco/paths.htm',
      name: 'Paths',
    };
  }
  // ECO funnel
  if (/\/app\/app-insights\/dashboard\/funnel/.test(pathname || '')) {
    return {
      path: '/learning-center/content/eco/funnel.htm',
      name: 'Funnel',
    };
  }
  // user timeline
  if (/\/app-insights\/dashboard\/user-timeline/.test(pathname)) {
    return {
      path: '/learning-center/content/eco/user_timeline.html',
      name: 'User Timeline',
    };
  }
  // semantic mapper
  if (/\/activation\/semantic-mapper/.test(pathname)) {
    return {
      path: '/learning-center/content/eco/semantic_mapper.htm',
      name: 'Semantic Mapper',
    };
  }
  // metric builder
  if (/\/activation\/metric-builder/.test(pathname)) {
    return {
      path: '/learning-center/content/eco/metric_builder.htm',
      name: 'Metric Builder',
    };
  }
  // management
  if (/\/activation\/management/.test(pathname)) {
    return {
      path: '/learning-center/content/eco/management.htm',
      name: 'Management',
    };
  }

  // livelens
  if (/\/activation\/livelens/.test(pathname)) {
    return {
      path: '/learning-center/content/eco/livelens.htm',
      name: 'LiveLens',
    };
  }

  return {
    path: '',
    name: '',
  };
};

const pathPreifx = '/app/viewer-insights';

const PATH_TO_HELP_LINK_TITLE = {
  [`${pathPreifx}/content_overview_page/`]: 'Series Overview Dashboard Help',
  [`${pathPreifx}/device_overview/`]: 'Device Overview Dashboard Help',
  [`${pathPreifx}/paths_page/`]: 'Series Pathing Dashboard Help',
  [`${pathPreifx}/content_page/`]: 'Content Page Help',
  [`${pathPreifx}/day_and_time_page/`]: 'Time Dashboard Help',
  [`${pathPreifx}/device_page/`]: 'Device Page Help',
  [`${pathPreifx}/dma_page/`]: 'DMA Page Help',
  [`${pathPreifx}/lead_in__out_page/`]: 'LeadIn LeadOut Page Help',
  [`${pathPreifx}/segments_list/`]: 'Segment Management Help',
  [`${pathPreifx}/segment_overview/`]: 'Segment Overview Help',
  [`${pathPreifx}/unique_viewers_page/`]: 'Unique Viewers Page Help',
  [`${pathPreifx}/channel_overview_page/`]: 'Channel Overview Dashboard Help',
  [`${pathPreifx}/channel_page/`]: 'Channel Page Help',
  [`${pathPreifx}/episode_overview_page/`]: 'Episode Overview Dashboard Help',
  [`${pathPreifx}/affiliate_overview_page/`]: 'Affiliate Overview Dashboard Help',
  [`${pathPreifx}/channel_path_page/`]: 'Channel Pathing Dashboard Help',
  [`${pathPreifx}/episode_path_page/`]: 'Episode Pathing Dashboard Help',
  [`${pathPreifx}/ci_customer_view_explore/`]: 'Analytics Workbench Help',
  [`${pathPreifx}/overview_page/`]: 'Overview Dashboard Help',
  [`${pathPreifx}/ads/`]: 'Ad Campaigns Dashboard Help',
  [`${pathPreifx}/ads_detail/`]: 'Ad Campaign Details Help',
  [`${pathPreifx}/dashboard/`]: 'Social + Streaming Dashboard Help',
  [`${pathPreifx}/viewer-acquisition/`]: 'Viewer Acquisition Dashboard Help',
  [`${pathPreifx}/viewer-retention/`]: 'Viewer Retention Help',
  [`${pathPreifx}/customer_reports/`]: 'Saved Workbench Analysis Help',
  [`${pathPreifx}/social/dashboard/`]: 'Social Insights Dashboard Help',
};
const new_help_ci_prefix = '/learning-center/content/vi_application/';
const NEW_LERANING_CENTER_HELP = {
  [`${pathPreifx}/content_overview_page/`]: `${new_help_ci_prefix}dashboards/series-overview-dashboard.html`,
  [`${pathPreifx}/device_overview/`]: `${new_help_ci_prefix}dashboards/device-dashboard.html`,
  [`${pathPreifx}/paths_page/`]: `${new_help_ci_prefix}dashboards/series-pathing-dashboard.html`,
  [`${pathPreifx}/content_page/`]: `${new_help_ci_prefix}dashboards/content-page.html`,
  [`${pathPreifx}/day_and_time_page/`]: `${new_help_ci_prefix}dashboards/timestamp-time-page.html`,
  [`${pathPreifx}/device_page/`]: `${new_help_ci_prefix}dashboards/device-page.html`,
  [`${pathPreifx}/dma_page/`]: `${new_help_ci_prefix}dashboards/dma-page.html`,
  [`${pathPreifx}/lead_in__out_page/`]: `${new_help_ci_prefix}dashboards/leadin-leadout-page.html`,
  [`${pathPreifx}/segments_list/`]: `${new_help_ci_prefix}dashboards/segment-management-dashboard.html`,
  [`${pathPreifx}/segment_overview/`]: `${new_help_ci_prefix}dashboards/segment-overview-dashboard.html`,
  [`${pathPreifx}/channel_overview_page/`]: `${new_help_ci_prefix}dashboards/channel-overview-dashboard.html`,
  [`${pathPreifx}/channel_page/`]: `${new_help_ci_prefix}dashboards/channel-page.html`,
  [`${pathPreifx}/episode_overview_page/`]: `${new_help_ci_prefix}dashboards/episode-overview-dashboard.html`,
  [`${pathPreifx}/affiliate_overview_page/`]: `${new_help_ci_prefix}dashboards/affiliate-overview-dashboard.html`,
  [`${pathPreifx}/channel_path_page/`]: `${new_help_ci_prefix}dashboards/channel-pathing-dashboard.html`,
  [`${pathPreifx}/episode_path_page/`]: `${new_help_ci_prefix}dashboards/episode-pathing-dashboard.html`,
  [`${pathPreifx}/ci_customer_view_explore/`]: `${new_help_ci_prefix}dashboards/analytics-workbench-dashboard.html`,
  [`${pathPreifx}/overview_page/`]: `${new_help_ci_prefix}dashboards/overview-dashboard.html`,
  [`${pathPreifx}/viewer-acquisition/`]: `${new_help_ci_prefix}dashboards/viewer-acquisition-dashboard.htm`,
  [`${pathPreifx}/viewer-retention/`]: `${new_help_ci_prefix}dashboards/viewer-retention.htm`,
  [`${pathPreifx}/unique_viewers_page/`]: `${new_help_ci_prefix}dashboards/unique-viewers-page.html`,
  [`${pathPreifx}/customer_reports/`]: `${new_help_ci_prefix}dashboards/analytics-workbench-saved-analyses.html`,
  [`${pathPreifx}/dashboard/`]: '/learning-center/content/vi_application/dashboards/social-streaming-dashboard.htm',
  [`${pathPreifx}/social/dashboard/`]: '/learning-center/content/si_application/dashboards/social_dashboard.htm',
  [`${pathPreifx}/ads/`]: `${new_help_ci_prefix}dashboards/ad-campaign-dashboard.html`,
  [`${pathPreifx}/ads_detail/`]: `${new_help_ci_prefix}dashboards/ad-campaign-details.html`,
};

function VIHelpDisplayName() {
  const path = `${document.location.pathname}/`;
  return PATH_TO_HELP_LINK_TITLE[path] || PATH_TO_HELP_LINK_TITLE[`${pathPreifx}/overview_page/`];
}

function VIHelpUrl() {
  const path = `${document.location.pathname}/`;
  return NEW_LERANING_CENTER_HELP[path] || NEW_LERANING_CENTER_HELP[`${pathPreifx}/overview_page/`];
}

const SI_PATH_TO_PAGE_NAME = {
  '/leaderboard': 'Leaderboard',
  '/accounts': 'Accounts',
  '/analytics': 'Analytics',
  '/campaigns': 'Campaigns',
  '/contenttags': 'Content Tags',
  '/settings': 'Settings',
};

const SI_PATH_TO_HELP_LINK = {
  '/leaderboard': '/learning-center/content/si_application/dashboards/leaderboard_dashboard.htm',
  '/accounts': '/learning-center/content/si_application/dashboards/accounts_dashboard.htm',
  '/analytics': '/learning-center/content/si_application/dashboards/analytics_dashboard.htm',
  '/campaigns': '/learning-center/content/si_application/dashboards/campaigns_dashbaord.htm',
  '/contenttags': '/learning-center/content/si_application/dashboards/content_tag_dashboard.htm',
  '/settings': '/learning-center/content/si_application/dashboards/settings_dashboard.htm',
};

const SIHelpDisplayName = () => {
  const subPath = window.location.pathname.replace('/app/viewer-insights/social', '');
  const titleTuple = Object.entries(SI_PATH_TO_PAGE_NAME).find(([key]) => subPath.startsWith(key));

  return titleTuple && `${titleTuple[1]} Help`;
};

function SIHelpUrl() {
  const subPath = window.location.pathname.replace('/app/viewer-insights/social', '');
  const linkTuple = Object.entries(SI_PATH_TO_HELP_LINK).find(([key]) => subPath.startsWith(key));

  return linkTuple && linkTuple[1];
}

export {
  PATH_TO_HELP,
  getHelpLinks,
  PATH_TO_HELP_LINK_TITLE,
  NEW_LERANING_CENTER_HELP,
  VIHelpDisplayName,
  VIHelpUrl,
  SIHelpDisplayName,
  SIHelpUrl,
};
