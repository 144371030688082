type Menu = {
  displayName: string;
  url?: string;
  submenus?: Menu[];
};

export function normalizeMenu(menu: Menu | undefined, product: string) {
  if (menu) {
    const common = {
      id: `${product.toLowerCase()}-${menu.displayName}`,
      category: product,
      displayName: menu.displayName,
    };

    return menu.submenus?.length
      ? {
          ...common,
          type: 'group',
          children: menu.submenus.map(child => normalizeMenu(child, product)),
        }
      : {
          ...common,
          url: menu.url,
          valid: () => true,
        };
  }
  return undefined;
}
