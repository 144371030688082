import React from 'react';
import type { MenuProps as BaseMenuProps } from 'antd';
import Header, { GroupHeader } from './Header';
import Item from './Item';
import type { MenuConfig, MenuItem } from './type';

function insertDivider(groups: MenuConfig['groups']) {
  if (groups) {
    const newGroups = groups.reduce((acc, val, i) => {
      if (i < groups.length - 1) {
        return acc.concat([
          val,
          {
            type: 'divider',
            key: `divider-${i}`,
          },
        ]);
      }
      return acc.concat(val);
    }, [] as Array<Array<MenuItem> | { type: 'divider'; key: string }>);
    return newGroups.flat();
  }
  return groups;
}

// get item by item type. item has children or not
function getItemByType(
  item: MenuItem | { type: 'divider'; key: string },
  openKeys: string[],
  level: number,
) {
  if ('type' in item && item.type === 'divider') {
    return item;
  }

  if ('children' in item) {
    const open = openKeys.includes(item.id);
    const label =
      item.type === 'expand' ? (
        <Header open={open} loading={item.loading} title={item.displayName} level={level + 1} />
      ) : (
        <GroupHeader level={level + 1} title={item.displayName} />
      );

    return {
      type: item.type,
      key: item.id,
      label,
      children: item.children.map(child => getItemByType(child, openKeys, level + 1)),
    };
  }

  return {
    key: item.id,
    label: <Item url={item.url} displayName={item.displayName} level={level} isNew={item.isNew} />,
  };
}

export function useMenuItems(config: MenuConfig, openKeys: string[], loading?: boolean) {
  return React.useMemo<BaseMenuProps['items']>(() => {
    const open = openKeys.includes(config.product);
    const children = insertDivider(config.groups)?.map(item => getItemByType(item, openKeys, 0));
    return [
      {
        key: config.product,
        label: (
          <Header
            loading={loading}
            open={open}
            icon={config.icon}
            title={config.displayName}
            suffix={config.suffix}
            prefix={config.prefix}
            level={0}
          />
        ),
        children,
      },
    ];
  }, [config, openKeys, loading]);
}
