import styled from 'styled-components';
import { colorMap } from '@utils/colorMap';

export const ProductWrapper = styled.div`
  height: calc(100% - 40px);
  overflow: auto;
`;

export const Wrapper = styled.div<{ showMenu: boolean }>`
  position: absolute;
  transform: translateX(-1000%);
  z-index: -1000;
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
  * {
    font-family: Open Sans, sans-serif;
  }
  ${props =>
    props.showMenu
      ? `
    & {
      left: 0;
      right: 0px;
      top: 55px;
      bottom: 0;
      z-index: 1000;
      transform: translateX(0);
      opacity: 1;
    }
  `
      : ''}
`;

export const SidebarWrapper = styled.div<{ showMenu: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  width: 268px;
  top: 0;
  bottom: 0;
  background-color: ${() => colorMap.backgroundColor};
  box-shadow: 6px 0px 5px ${() => colorMap.boxShadow};
  transform: translateX(-270px);
  transition: 0.2s all ease-in-out;
  overflow-x: hidden;
  ${props =>
    props.showMenu
      ? `
  & {
    transform: translateX(0px);
  }
  `
      : ''}
  .viewer_insights ~ .social_insights {
    padding-left: 40px;
  }
`;

export const ContentWrapper = styled.div<{ showContent: boolean }>`
  display: flex;
  align-items: center;
  position: absolute;
  left: 269px;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: ${() => colorMap.backgroundColor};
  opacity: 0.9;
  box-shadow: 6px 0px 5px ${() => colorMap.boxShadow};
  overflow-x: hidden;
  display: none;
  p {
    font-size: 24px;
    width: 60%;
    margin: 0 auto;
  }
  ${props =>
    props.showContent
      ? `
    & {
      display: flex;
    }
  `
      : ''}
`;

export const ContactBtnRow = styled.span`
  display: block;
  text-align: center;
  margin: 1em;
`;
export const CloseContainer = styled.div<{ showClose: boolean }>`
   {
    cursor: pointer;
    position: fixed;
    top: 20px;
    right: 20px;
    font-size: 24px;
    ${props =>
      props.showClose
        ? `
    display: block;
  `
        : `display: none`}
  }
`;
