import * as React from 'react';
import { Space } from 'antd';
import Icon from '@ant-design/icons';
import { IconButton } from '@subApps/common/components';
import { getIcon, ICON_NAMES } from '@resources/icons';
import SettingPanelSection from './SettingPanelSection';
import { StyledDrawer } from './styled';
import { SettingProps } from '..';
import { useSettingRoutes } from './utils';

const SettingPanel = ({ compact = false }: SettingProps) => {
  const [open, setOpen] = React.useState(false);
  const routes = useSettingRoutes();
  const handleCloseDrawer = React.useCallback(() => setOpen(false), []);

  return (
    <>
      <Space onClick={() => setOpen(true)}>
        <IconButton>
          <Icon component={getIcon(ICON_NAMES.setting)} />
        </IconButton>
        {compact && <span>Settings</span>}
      </Space>
      <StyledDrawer open={open} title="Settings" width={332} onClose={handleCloseDrawer}>
        {routes.map(it => (
          <SettingPanelSection
            key={it.name}
            name={it.name}
            items={it.items}
            onLinkClick={handleCloseDrawer}
          />
        ))}
      </StyledDrawer>
    </>
  );
};

export default SettingPanel;
