import useSWR from 'swr';
import { pulse5Fetcher } from '@subApps/navApp/apis/request';
import { User as UserDef } from './typed';
import User from './user.model';

export default () => {
  const { data, error } = useSWR<UserDef>('/user/profile', pulse5Fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  return {
    data: data ? new User(data) : undefined,
    error,
    loading: !data,
  };
};
