import styled from 'styled-components';
import { colorMap } from '@utils/colorMap';
import { IconButton } from '@subApps/common/components';
import { MenuOutlined } from '@ant-design/icons';

export const StyledMenuOutlined = styled(MenuOutlined)``;

export default styled(IconButton)`
  position: absolute;
  left: 0;
  top: 50%;
  margin: 0;
  padding: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    ${StyledMenuOutlined} {
      color: ${() => colorMap.primarySelected};
    }
  }
  > span {
    color: ${() => colorMap.grayText};
  }
  &:hover {
    ${StyledMenuOutlined} {
      color: ${() => colorMap.primarySelected};
    }
  }
`;
