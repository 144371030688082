import React from 'react';
import styled from 'styled-components';
import MenuContext from './MenuContext';

const Menu: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [currentExpanded, setCurrentExpanded] = React.useState<string>();

  const changeCurrentExpanded = React.useCallback((val: string) => {
    setCurrentExpanded(v => (v === val ? undefined : val));
  }, []);

  const storeState = React.useMemo(
    () => ({ currentExpanded, setCurrentExpanded: changeCurrentExpanded }),
    [currentExpanded, changeCurrentExpanded],
  );

  return <MenuContext.Provider value={storeState}>{children}</MenuContext.Provider>;
};

export const Wrapper = styled.div`
  margin: 0;
  padding: 0;
`;

export { default as MenuItem } from './MenuItem';
export { default as SubMenu } from './SubMenu';
export default React.memo(Menu);
