import React from 'react';
import { ManualAlertEvent, ManualAlert } from '@subApps/navApp/hooks/useData/useAlerts/typed';
import { METRIC_INFO } from '@constants/metric';
import { PRODUCT_URL_PREFIX } from '@constants/products';

interface Props {
  event: ManualAlertEvent;
  isAI: boolean;
  metricsMeta: object;
}

const formatCondition = (alert: ManualAlert, metricsMeta) => {
  const { metric } = METRIC_INFO[alert.metric];
  const metricInfo = metricsMeta?.[metric];
  const displayName = metricInfo?.displayName || METRIC_INFO[alert.metric].name;

  return `${displayName} ${alert.direction} ${alert.threshold} ${METRIC_INFO[alert.metric].units}`;
};

const AlertItem = ({ event, isAI, metricsMeta }: Props) => {
  const prefix = isAI ? PRODUCT_URL_PREFIX.AI : PRODUCT_URL_PREFIX.EI;
  const href = `${prefix}/alert/manual/diagnostics?event=${event.id}`;
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    window.history.pushState(null, event.alert!.name, href);
  };

  return (
    <a href={href} onClick={handleClick}>
      <span className="left" title={`${event.alert!.name || ''}`}>
        {event.alert!.name || ''}
      </span>
      <span className="right" title={formatCondition(event.alert!, metricsMeta)}>
        {formatCondition(event.alert!, metricsMeta)}
      </span>
    </a>
  );
};

export default AlertItem;
