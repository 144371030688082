import { createContext } from 'react';
import { PRODUCTS } from '@constants/products';

type NavMenuContextType = {
  openKeys: string[];
  selectedKeys: string[];
  pathName: string;
  setSelectedKeys: (keys: string[]) => void;
  onOpenChange: (keys: string[]) => void;
  onClick: (item: any, keys: string[]) => void;
  setShowNoAccess: (show: boolean) => void;
  setProduct: (product: PRODUCTS) => void;
};

export const NavMenuContext = createContext<NavMenuContextType>({} as NavMenuContextType);
