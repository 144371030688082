import { Theme } from '@constants/theme';

import darkConvivaLogo from './dark/Conviva_Logo.png';
import lightConvivaLogo from './light/Conviva_Logo.png';

import darkMobileConvivaLogo from './dark/Conviva_Logo_mobile.png';
import lightMobileConvivaLogo from './light/Conviva_Logo_mobile.png';

export enum IMAGE_NAMES {
  convivaLogo = 'convivaLogo',
}

const images = {
  dark: {
    mobile: {
      [IMAGE_NAMES.convivaLogo]: darkMobileConvivaLogo,
    },
    desktop: {
      [IMAGE_NAMES.convivaLogo]: darkConvivaLogo,
    },
  },
  light: {
    mobile: {
      [IMAGE_NAMES.convivaLogo]: lightMobileConvivaLogo,
    },
    desktop: {
      [IMAGE_NAMES.convivaLogo]: lightConvivaLogo,
    },
  },
};

export default images;

export const getImage = (resolution: string, name: IMAGE_NAMES, theme: Theme = 'light') =>
  (images[theme][resolution][name] || null) as any;
