import { Admin, CustomerService, Developer } from '@constants/roles';
import { User as UserDef } from './typed';

class User {
  private user: UserDef;

  constructor(def: UserDef) {
    this.user = def;
  }

  public isAdmin() {
    return this.user.role === Admin;
  }

  public isCustomerService() {
    return this.user.role === CustomerService;
  }

  public isDeveloper() {
    return this.user.role === Developer;
  }

  public static isViewerOnly(nav) {
    if (nav && Object.entries(nav).length > 0) {
      const children = nav?.menu.ei?.filter(item => item.children !== undefined)[0];
      return children?.children?.some(m => m.name === 'Viewers');
    }
  }

  get customerName() {
    return this.user.customer.name;
  }

  get timezone() {
    return this.user.customer.timezone;
  }

  get self() {
    return this.user;
  }

  get customerId() {
    return this.user.customerId;
  }

  get username() {
    return this.user.username;
  }

  get profileId() {
    return this.user.id.toString();
  }
}

export default User;
