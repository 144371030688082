import React from 'react';
import { get } from 'lodash';
import { PRODUCTS, PRODUCT_DISPLAY_NAMES_NEW_NAV } from '@constants/products';
import {
  AI_ALERTS_PULSE5,
  MANUAL_ALERTS,
  REAL_TIME,
} from '@subApps/navApp/hooks/useData/usePulse5Features/featureNames';
import Ad from '@resources/icons/product/Ad.svg';
import { Menu } from '../../components/Menu';
import { useConfigWithSavedDashboard } from '../Dashboard/hooks';
import { useValidConfig } from '../../hooks/useValidConfig';

const config = {
  product: PRODUCTS.AI,
  icon: <Ad />,
  displayName: PRODUCT_DISPLAY_NAMES_NEW_NAV[PRODUCTS.AI],
  groups: [
    [
      {
        id: 'ad-trends',
        category: PRODUCTS.AI,
        displayName: 'Trends',
        url: '/app/ad-insights/dashboard/trends',
        valid: () => true,
      },
      {
        id: 'ad-real-time',
        category: PRODUCTS.AI,
        displayName: 'Real-Time',
        url: '/app/ad-insights/dashboard/real-time',
        valid: ({ features }) => get(features, REAL_TIME, false),
      },
      {
        id: 'ad-ai-alerts',
        category: PRODUCTS.AI,
        displayName: 'AI Alerts',
        url: '/app/experience-insights/dashboard/ai-alerts',
        valid: ({ features }) => get(features, AI_ALERTS_PULSE5, false),
        ghost: true,
      },
      {
        id: 'ad-ma-alerts',
        category: PRODUCTS.AI,
        displayName: 'Manual Alerts',
        url: '/app/ad-insights/alert/manual/summary',
        valid: ({ features }) => get(features, MANUAL_ALERTS, false),
      },
      {
        id: 'ad-customizable-dashboard',
        category: PRODUCTS.AI,
        displayName: 'Custom Dashboards',
        url: '/app/custom-dashboards?source=ad',
        valid: () => true,
        target: '_blank',
      },
    ],
  ],
};

const Ads = () => {
  const validConfig = useValidConfig(config);
  const menuConfig = useConfigWithSavedDashboard(PRODUCTS.AI, validConfig);
  return <Menu config={menuConfig} />;
};

export default Ads;
