import { AlertsSummary } from '../hooks/useData/useAIAlertsSummary/typed';

// higher the number, higher the priority
const sortingPriority = {
  Info: 1,
  Warning: 2,
  Critical: 3,
  Ended: 4,
};

const sortByPriority = events => {
  events.sort(
    (alertEvent1, alertEvent2) =>
      sortingPriority[alertEvent1.eventType] - sortingPriority[alertEvent2.eventType],
  );
};

const getImpactedUniquesValue = events => {
  return events[events.length - 1].cumulativeImpactedUniques;
};

const sortByCumulativeImpactedUniques = (item1, item2) => {
  const firstItem = item1.cumulativeImpactedUniquesRaw;
  const secondItem = item2.cumulativeImpactedUniquesRaw;

  return secondItem - firstItem;
};

function sortAlertsSummary(alertsSummary: AlertsSummary[]) {
  alertsSummary.forEach(alert => {
    const { alertEvents } = alert;
    const filteredEvents = alertEvents.filter(({ eventTime }) => !!eventTime);
    sortByPriority(filteredEvents);
    alert.eventTimeRaw = filteredEvents[0].eventTime;
    alert.cumulativeImpactedUniquesRaw = getImpactedUniquesValue(filteredEvents);
  });

  alertsSummary.sort((item1, item2) => {
    const firstItem = item1.eventTimeRaw;
    const secondItem = item2.eventTimeRaw;

    if (firstItem && secondItem) {
      return secondItem - firstItem;
    }

    return sortByCumulativeImpactedUniques(item1, item2);
  });

  return alertsSummary;
}

export default sortAlertsSummary;
