/* eslint-disable camelcase */
export interface MetricDefinition {
  abbr: string;
  name: string;
  cm_metric: string;
  cm_value: number;
  cm_label: string;
  direction: '<' | '>';
  units: string;
  type: string;
  idx: number;
  metric: string;
}

export interface MetricInfo {
  [abbr: string]: MetricDefinition;
}

// copied from pulse4/alerts3/metrics.py
export const METRIC_INFO: MetricInfo = {
  vsf: {
    abbr: 'VSF',
    name: 'Video Start Failures',
    cm_metric: 'attempts',
    cm_value: 1000,
    cm_label: 'attempts',
    direction: '>',
    units: '%',
    type: '',
    idx: 0,
    metric: 'VideoStartFailures',
  },
  ebvs: {
    abbr: 'EBVS',
    name: 'Exits Before Video Start',
    cm_metric: 'attempts',
    cm_value: 1000,
    cm_label: 'attempts',
    direction: '>',
    units: '%',
    type: '',
    idx: 1,
    metric: 'ExitBeforeVideoStarts',
  },
  rr: {
    abbr: 'Rebuff %',
    name: 'Rebuffering Ratio',
    cm_metric: 'pcv',
    cm_value: 500,
    cm_label: 'plays',
    direction: '>',
    units: '%',
    type: '',
    idx: 2,
    metric: 'RebufferingRatio',
  },
  pcv: {
    abbr: 'Plays',
    name: 'Concurrent Plays',
    cm_metric: 'min',
    cm_value: 5,
    cm_label: 'minutes',
    direction: '<',
    units: 'plays',
    type: '',
    idx: 3,
    metric: 'ConcurrentPlays',
  },
  abr: {
    abbr: 'Avg Bitrate',
    name: 'Average Bitrate',
    cm_metric: 'pcv',
    cm_value: 500,
    cm_label: 'plays',
    direction: '<',
    units: 'Mbps',
    type: '',
    idx: 4,
    metric: 'Bitrate',
  },
  ast: {
    abbr: 'VST',
    name: 'Video Startup Time',
    cm_metric: 'attempts',
    cm_value: 1000,
    cm_label: 'attempts',
    direction: '>',
    units: 'sec',
    type: '',
    idx: 5,
    metric: 'VideoStartTime',
  },
  attempts: {
    abbr: 'Attempts',
    name: 'Total Attempts',
    cm_metric: 'min',
    cm_value: 5,
    cm_label: 'minutes',
    direction: '<',
    units: 'attempts',
    type: '',
    idx: 6,
    metric: 'Attempts',
  },
  pods_started: {
    abbr: 'Started',
    name: 'Ad Breaks Started',
    cm_metric: 'min',
    cm_value: 5,
    cm_label: 'minutes',
    direction: '<',
    units: 'Ad Breaks',
    type: 'pods',
    idx: 0,
    metric: 'pods_started',
  },
  pods_completed: {
    abbr: 'Completed',
    name: 'Ad Breaks Completed',
    cm_metric: 'pods_ended',
    cm_value: 500,
    cm_label: 'Ad Breaks Ended',
    direction: '<',
    units: 'Ad Breaks',
    type: 'pods',
    idx: 1,
    metric: 'pods_completed',
  },
  pods_abandoned: {
    abbr: 'Abandoned',
    name: 'Ad Breaks Abandoned',
    cm_metric: 'pods_ended',
    cm_value: 500,
    cm_label: 'Ad Breaks Ended',
    direction: '>',
    units: '%',
    type: 'pods',
    idx: 2,
    metric: 'pods_abandoned',
  },
  pods_duration: {
    abbr: 'Duration',
    name: 'Ad Breaks Actual Duration',
    cm_metric: 'pods_completed',
    cm_value: 500,
    cm_label: 'Ad Breaks Completed',
    direction: '<',
    units: 'sec',
    type: 'pods',
    idx: 3,
    metric: 'pods_duration',
  },
  pods_ended_play: {
    abbr: 'Ended Play',
    name: 'Ad Breaks / Ended Attempts',
    cm_metric: 'pods_completed',
    cm_value: 500,
    cm_label: 'Ad Breaks Completed',
    direction: '>',
    units: 'Ad Breaks',
    type: 'pods',
    idx: 4,
    metric: 'pods_ended_play',
  },
  pods_ended: {
    abbr: 'Ended',
    name: 'Ad Breaks Ended',
    cm_metric: 'min',
    cm_value: 5,
    cm_label: 'minutes',
    direction: '<',
    units: 'Ad Breaks',
    type: 'pods',
    idx: 5,
    metric: 'pods_ended',
  },
  ads_concurrent_plays: {
    abbr: 'Plays',
    name: 'Ad Concurrent Plays',
    cm_metric: 'min',
    cm_value: 500,
    cm_label: 'minutes',
    direction: '<',
    units: '',
    type: 'ads',
    idx: 8,
    metric: 'ads_concurrent_plays',
  },
  ads_plays: {
    abbr: 'Plays',
    name: 'Ad Impressions',
    cm_metric: 'min',
    cm_value: 500,
    cm_label: 'minutes',
    direction: '<',
    units: '',
    type: 'ads',
    idx: 9,
    metric: 'Plays',
  },
  ads_rebuffering_ratio: {
    abbr: 'Rebuff %',
    name: 'Ad Rebuffering Ratio',
    cm_metric: 'ads_plays',
    cm_value: 500,
    cm_label: 'Ad Impressions',
    direction: '>',
    units: '%',
    type: 'ads',
    idx: 10,
    metric: 'RebufferingRatio',
  },
  ads_average_bitrate: {
    abbr: 'Avg Bitrate',
    name: 'Ad Average Bitrate',
    cm_metric: 'ads_plays',
    cm_value: 500,
    cm_label: 'Ad Impressions',
    direction: '<',
    units: 'Mbps',
    type: 'ads',
    idx: 11,
    metric: 'Bitrate',
  },
  ads_video_startup_time: {
    abbr: 'VST',
    name: 'Ad Startup Time',
    cm_metric: 'ads_plays',
    cm_value: 500,
    cm_label: 'Ad Impressions',
    direction: '>',
    units: 'sec',
    type: 'ads',
    idx: 12,
    metric: 'VideoStartTime',
  },
  vmf: {
    abbr: 'VPF',
    name: 'Video Playback Failures',
    cm_metric: 'ended plays',
    cm_value: 1000,
    cm_label: 'ended plays',
    direction: '>',
    units: '%',
    type: '',
    idx: 13,
    metric: 'VideoPlaybackFailures',
  },
  vrt: {
    abbr: 'VRT',
    name: 'Video Restart Time',
    cm_metric: 'ended plays',
    cm_value: 1000,
    cm_label: 'ended plays',
    direction: '>',
    units: 'sec',
    type: '',
    idx: 14,
    metric: 'VideoRestartTime',
  },
  nir: {
    abbr: 'CIRR',
    name: 'Connection Induced Rebuffering Ratio',
    cm_metric: 'pcv',
    cm_value: 1000,
    cm_label: 'plays',
    direction: '>',
    units: '%',
    type: '',
    idx: 15,
    metric: 'ConnectionInducedRebufferingRatio',
  },
  ad_attempts: {
    abbr: 'Attempts',
    name: 'Ad Attempts',
    cm_metric: 'min',
    cm_value: 5,
    cm_label: 'minutes',
    direction: '<',
    units: 'attempts',
    type: 'ads',
    idx: 16,
    metric: 'Attempts',
  },
  ad_start_failures: {
    abbr: 'ASF',
    name: 'Ad Start Failures',
    cm_metric: 'attempts',
    cm_value: 1000,
    cm_label: 'attempts',
    direction: '>',
    units: '%',
    type: 'ads',
    idx: 17,
    metric: 'VideoStartFailures',
  },
  exits_before_ad_start: {
    abbr: 'EBAS',
    name: 'Exits Before Ad Start',
    cm_metric: 'attempts',
    cm_value: 1000,
    cm_label: 'attempts',
    direction: '>',
    units: '%',
    type: 'ads',
    idx: 18,
    metric: 'ExitBeforeVideoStarts',
  },
  ad_playback_failures: {
    abbr: 'APF',
    name: 'Ad Playback Failures',
    cm_metric: 'ended plays',
    cm_value: 1000,
    cm_label: 'ended plays',
    direction: '>',
    units: '%',
    type: 'ads',
    idx: 19,
    metric: 'VideoPlaybackFailures',
  },
  slates_duration: {
    abbr: 'ASD',
    name: '% Slate Duration',
    cm_metric: 'ss_ad_plays',
    cm_value: 500,
    cm_label: 'Server Side Ad Plays',
    direction: '>',
    units: '%',
    type: 'ads',
    idx: 20,
    metric: 'slates_duration',
  },
  slates_started: {
    abbr: 'ASP',
    name: '% Slate Plays',
    cm_metric: 'ss_ad_plays',
    cm_value: 500,
    cm_label: 'Server Side Ad Plays',
    direction: '>',
    units: '%',
    type: 'ads',
    idx: 21,
    metric: 'slates_started',
  },
  vsf_technical: {
    abbr: 'VSF-T',
    name: 'Video Start Failures Technical',
    cm_metric: 'attempts',
    cm_value: 1000,
    cm_label: 'Attempts',
    direction: '>',
    units: '%',
    type: '',
    idx: 22,
    metric: 'VideoStartFailuresTech',
  },
  vpf_technical: {
    abbr: 'VPF-T',
    name: 'Video Playback Failures Technical',
    cm_metric: 'ended plays',
    cm_value: 1000,
    cm_label: 'Ended Plays',
    direction: '>',
    units: '%',
    type: '',
    idx: 23,
    metric: 'VideoPlaybackFailuresTech',
  },
  vsf_business: {
    abbr: 'VSF-B',
    name: 'Video Start Failures Business',
    cm_metric: 'attempts',
    cm_value: 1000,
    cm_label: 'Attempts',
    direction: '>',
    units: '%',
    type: '',
    idx: 24,
    metric: 'VideoStartFailuresBusiness',
  },
  vpf_business: {
    abbr: 'VPF-B',
    name: 'Video Playback Failures Business',
    cm_metric: 'ended plays',
    cm_value: 1000,
    cm_label: 'Ended Plays',
    direction: '>',
    units: '%',
    type: '',
    idx: 25,
    metric: 'VideoPlaybackFailuresBusiness',
  },
};
