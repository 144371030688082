import { Theme } from '@constants/theme';
import darkSettingIcon from './dark/setting-icon.svg';
import darkHelpIcon from './dark/help-icon.svg';
import darkHomeIcon from './dark/home-icon.svg';
import darkNotificationIcon from './dark/notification-icon.svg';
import darkFeedback from './dark/feedback-icon.svg';

import lightSettingIcon from './light/setting-icon.svg';
import lightHelpIcon from './light/help-icon.svg';
import lightHomeIcon from './light/home-icon.svg';
import lightNotificationIcon from './light/notification-icon.svg';
import lightFeedback from './light/feedback-icon.svg';
import lightArrowUp from './light/arrow-up-icon.svg';
import lightBulb from './light/bulb.svg';

export enum ICON_NAMES {
  setting = 'setting',
  help = 'help',
  home = 'home',
  notification = 'notification',
  feedback = 'feedback',
  arrowUp = 'arrowUp',
  bulb = 'bulb',
}

const icons = {
  dark: {
    [ICON_NAMES.setting]: darkSettingIcon,
    [ICON_NAMES.help]: darkHelpIcon,
    [ICON_NAMES.home]: darkHomeIcon,
    [ICON_NAMES.notification]: darkNotificationIcon,
    [ICON_NAMES.feedback]: darkFeedback,
    [ICON_NAMES.arrowUp]: lightArrowUp,
    [ICON_NAMES.bulb]: lightBulb,
  },
  light: {
    [ICON_NAMES.setting]: lightSettingIcon,
    [ICON_NAMES.help]: lightHelpIcon,
    [ICON_NAMES.home]: lightHomeIcon,
    [ICON_NAMES.notification]: lightNotificationIcon,
    [ICON_NAMES.feedback]: lightFeedback,
    [ICON_NAMES.arrowUp]: lightArrowUp,
    [ICON_NAMES.bulb]: lightBulb,
  },
};

export default icons;

export const getIcon = (name: ICON_NAMES, theme: Theme = 'light') =>
  (icons[theme][name] || null) as any;
