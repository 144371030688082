import styled from 'styled-components';
import { colorMap } from '@utils/colorMap';
import { Divider } from 'antd';
import { darken, ellipsis } from 'polished';

export const OverlayWrapper = styled.div`
  width: 440px;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: ${() => colorMap.backgroundColor};
  border: 1px solid ${() => colorMap.borderOne};
`;

export const MsgWrapper = styled.div`
  height: 30px;
  line-height: 30px;
  color: ${() => colorMap.borderOne};
  padding: 0 10px;
`;

export const OverlayTitle = styled.h4`
  padding: 0 10px;
  margin: 0;
  height: 35px;
  line-height: 35px;
  color: ${() => colorMap.text};
  font-weight: bold;
  span {
    &:first-child {
      margin-right: 5px;
    }
  }
`;

export const AlertsWrapper = styled.div`
  margin: 0;
  padding: 5px 0;
  list-style: none;
  > a {
    display: flex;
    width: 100%;
    height: 32px;
    line-height: 32px;
    transition: 0.25s all ease-in-out;
    overflow: hidden;
    cursor: pointer;
    box-sizing: border-box;
    padding: 0 10px;
    justify-content: space-between;
    color: ${() => colorMap.grayText};
    padding-right: 10px;
    box-sizing: border-box;
    text-decoration: none;
    &:hover {
      color: ${() => colorMap.grayText};
      background-color: ${() => colorMap.highlight};
    }
    &:active {
      color: ${() => colorMap.grayText};
      background-color: ${() => darken(0.05, colorMap.highlight)};
    }
    .left {
      ${ellipsis('225px')}
    }
    .right {
      ${ellipsis()}
      flex-grow: 1;
      text-align: right;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  background: ${() => colorMap.lineSeparator};
  &.ant-divider {
    margin: 0;
  }
`;
