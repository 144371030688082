import useSWR from 'swr';
import { pulse5Fetcher } from '@subApps/navApp/apis/request';
import { P4Nav } from './typed';

export default () => {
  const { data, error } = useSWR<P4Nav>('/nav/legacy', pulse5Fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  return {
    data,
    error,
    loading: !data,
  };
};
