import useSWR from 'swr';
import { pulse5Fetcher } from '@subApps/navApp/apis/request';
import { DefaultApp } from './typed';

export default () => {
  const { data, error } = useSWR<DefaultApp>('/default-landing-app', pulse5Fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  return {
    data,
    error,
    loading: !data,
  };
};
