import qs from 'query-string';
import { get } from 'lodash';
import { invokeIFCElement } from '@subApps/navApp/utils';
import { useNav, useUser, usePulse5Features } from '@subApps/navApp/hooks/useData';
import { useProduct } from '@subApps/navApp/hooks/useGlobal';
import User from '@subApps/navApp/hooks/useData/useUser/user.model';
import * as FF from '@subApps/navApp/hooks/useData/usePulse5Features/featureNames';
import { PRODUCTS, PRODUCT_URL_PREFIX } from '@constants/products';
import { isSIOnly } from '@utils/cookie';
import { P4Nav } from '@subApps/navApp/hooks/useData/useNav/typed';
import { ACCOUNT_OVERVIEW_MENUS } from '@constants/subMenu';
import { SettingPanelSectionProps } from './SettingPanelSection';

interface SettingMenuItemProps {
  nav: P4Nav;
  product: PRODUCTS;
  user: User;
  features: any;
}

interface SettingMenuItem {
  name?: string;
  items: Array<{
    name: string;
    action: string | ((input: SettingMenuItemProps) => string | (() => void));
    valid?: (input: SettingMenuItemProps) => boolean;
  }>;
  valid?: (input: SettingMenuItemProps) => boolean;
}

const SETTING_MENUS: SettingMenuItem[] = [
  {
    items: [
      {
        name: 'Filter Management',
        action: () => () => invokeIFCElement('open-filter-management', 'filter management'),
        valid: ({ nav }) =>
          !User.isViewerOnly(nav) &&
          nav.ei_setting.some(m => m.name === 'Filters') &&
          [
            PRODUCT_URL_PREFIX[PRODUCTS.EI],
            PRODUCT_URL_PREFIX[PRODUCTS.APP],
            PRODUCT_URL_PREFIX[PRODUCTS.AI],
          ].some(it => window.location.pathname.startsWith(it)),
      },
      {
        name: 'API Management',
        action: '/app/manage/apps/list',
        valid: ({ nav, product }) =>
          nav.ei_setting.some(m => m.name === 'API Management') && product !== PRODUCTS.APP,
      },
      {
        name: 'Executive Email v1.0',
        action: '/app/pulse/exec_email/',
        valid: ({ nav, product }) =>
          nav.ei_setting.some(m => m.name === 'Executive Email v1.0') && product !== PRODUCTS.APP,
      },
      {
        name: 'Executive Email v2.0',
        action: PRODUCT_URL_PREFIX[PRODUCTS.execReport],
        valid: ({ nav, product }) =>
          nav.ei_setting.some(m => m.name === 'Executive Email v2.0') && product !== PRODUCTS.APP,
      },
      {
        name: 'Notification Channels',
        action: ({ product }) => {
          if (product === PRODUCTS.APP) {
            return '/app/pulse/alerts4/app/manage_subscription';
          }

          if (product === PRODUCTS.AI) {
            return '/app/pulse/alerts4/adi/manage_subscription';
          }

          return '/app/pulse/alerts4/manage_subscription';
        },
        valid: ({ nav, product }) => {
          if (product === PRODUCTS.EI) {
            return nav.ei_setting.some(m => m.name === 'Notification Channels');
          }

          if (product === PRODUCTS.APP) {
            return nav.app_setting.some(m => m.name === 'Notification Channels');
          }

          if (product === PRODUCTS.AI) {
            return nav.ai_setting.some(m => m.name === 'Notification Channels');
          }

          return false;
        },
      },
      {
        name: 'Ad Account Settings',
        action: '/app/pulse/settings/adi/parameters',
        valid: ({ nav, product }) =>
          product === PRODUCTS.AI && nav.ai_setting.some(m => m.name === 'Ad Account Settings'),
      },
      {
        name: 'Account Details',
        action: ({ product }) => {
          if (product === PRODUCTS.APP) {
            return '/app/app-insights/account/details';
          }
          return '/app/pulse/package/';
        },
        valid: ({ nav, features, product }) => {
          if (product === PRODUCTS.APP) {
            return get(features, FF.ECO_TRENDS, false);
          }
          return nav!.ei_setting.some(m => m.name === 'Account Details');
        },
      },

      // remove this menu after GA release.
      // if CONTRACT_LEVEL_USAGE_DASHBOARD is true show pulse5 dashboard
      // else show pulse4 dashboard
      {
        name: 'Usage Dashboard',
        action: ({ product }) =>
          product === PRODUCTS.EI ? '/app/pulse/reports/25' : '/app/pulse/reports/60',
        valid: ({ nav, features, product }) => {
          const baseValid = nav.ei_setting.some(m => m.name === ACCOUNT_OVERVIEW_MENUS.USAGE);
          // Add permission control to ADS only.
          if (product === PRODUCTS.AI) {
            // Equal to or greater than 5 will display the Usage Dashboard,
            // while less than 5 will not show the Usage Dashboard.
            const adsValid = features[FF.AD_INSIGHTS] >= 5;
            return baseValid && adsValid;
          }
          return baseValid;
        },
      },

      {
        name: 'New Usage Dashboard',
        action: ({ features }) => {
          if (features[FF.CONTRACT_LEVEL_USAGE_DASHBOARD]) {
            return () => invokeIFCElement('open-usage-dashboard', ACCOUNT_OVERVIEW_MENUS.USAGE);
          }
          return '/app/experience-insights/dashboard/usage-dashboard';
        },
        valid: ({ nav, features }) => {
          const productInclude = [
            PRODUCT_URL_PREFIX[PRODUCTS.EI],
            PRODUCT_URL_PREFIX[PRODUCTS.APP],
            PRODUCT_URL_PREFIX[PRODUCTS.AI],
          ].some(it => window.location.pathname.startsWith(it));
          let valid = productInclude && features[FF.CONTRACT_LEVEL_USAGE_DASHBOARD];
          valid = valid && nav.ei_setting.some(m => m.name === ACCOUNT_OVERVIEW_MENUS.USAGE);
          return valid;
        },
      },
    ],
    valid: ({ user, nav }) =>
      !(user?.isCustomerService() || user?.isDeveloper() || User.isViewerOnly(nav) || isSIOnly()),
  },
  {
    name: 'ADMIN SETTINGS',
    items: [
      {
        name: 'User Management',
        action: '/app/pulse/users/',
        valid: ({ nav }) => nav.ei_setting.some(m => m.name === 'Users'),
      },
      {
        name: 'Session Source Data',
        action: '/app/pulse/ssd/',
        valid: ({ nav, product }) =>
          nav.ei_setting.some(m => m.name === 'Session Source Data') && product !== PRODUCTS.APP,
      },
      {
        name: 'Session Tags',
        action: '/app/pulse/subscribers/tags',
        valid: ({ nav, product }) =>
          nav.ei_setting.some(m => m.name === 'Session Tags') && product !== PRODUCTS.APP,
      },
      {
        name: 'Webhooks',
        action: ({ product }) => {
          if (product === PRODUCTS.APP) {
            return '/app/app-insights/settings/webhooks';
          }

          if (product === PRODUCTS.AI) {
            return '/app/ad-insights/settings/webhooks';
          }

          return '/app/experience-insights/settings/webhooks';
        },
        valid: ({ features, product, user }) => {
          return user.isAdmin() && (product === PRODUCTS.APP || features[FF.UNIFIED_WEBHOOKS_UI]);
        },
      },
      {
        name: 'Business Error Configuration',
        action: '/app/experience-insights/dashboard/error-config',
        valid: ({ user, product }) => product === PRODUCTS.EI && user.isAdmin(),
      },
      {
        name: 'Service Integrations',
        action: '/app/experience-insights/dashboard/service-integrations',
        valid: ({ user, product }) => product === PRODUCTS.EI && user.isAdmin(),
      },
    ],
    valid: ({ user, nav }) =>
      !(user?.isCustomerService() || user?.isDeveloper() || User.isViewerOnly(nav) || isSIOnly()),
  },
  {
    name: 'USER SETTINGS',
    items: [
      { name: 'My Profile', action: '/app/pulse/settings/personal_info' },
      {
        name: 'Logout',
        action: () => () => {
          location.href = '/logout';
        },
      },
    ],
  },
];

function genURL(url: string, product?: string) {
  return qs.stringifyUrl({ url, query: { product } });
}

export interface SettingRoute {
  name?: string;
  items: SettingPanelSectionProps['items'];
}

export function useSettingRoutes(itemStatus?: Record<string, boolean | string>) {
  const { data: nav } = useNav();
  const { data: user } = useUser();
  const { data: product } = useProduct();
  const { data: features } = usePulse5Features();

  if (!nav || !user || !features) return [];

  const routes: SettingRoute[] = SETTING_MENUS.filter(
    it => it.valid?.({ nav, features, product, user }) ?? true,
  )
    .map(it => ({
      name: it.name,
      items: it.items
        .filter(item => item.valid?.({ nav, features, product, user }) ?? true)
        .map(item => {
          const status = itemStatus?.[item.name];
          const action =
            typeof item.action === 'function'
              ? item.action({ nav, features, product, user })
              : item.action;

          if (typeof action === 'string') {
            return {
              name: item.name,
              status,
              url: genURL(action, product),
            };
          }

          return {
            name: item.name,
            status,
            onClick: action,
          };
        }),
    }))
    .filter(it => it.items.length > 0);

  return routes;
}
