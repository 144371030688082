import styled from 'styled-components';
import { lighten, darken } from 'polished';
import { colorMap } from '@utils/colorMap';

export default styled.button`
  display: inline-block;
  width: 30px;
  height: 30px;
  background: none;
  border: 0;
  font-size: 20px;
  padding: 0;
  color: ${() => colorMap.borderOne};
  line-height: 30px;
  user-select: none;
  outline: none;
  cursor: pointer;
  border-radius: 2px;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: ${() => lighten(0.1, colorMap.borderOne)};
  }
  &:active {
    color: ${() => darken(0.1, colorMap.borderOne)};
  }
`;
