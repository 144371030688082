import { isFunction } from 'lodash';
import { PRODUCTS } from '@constants/products';
import { SubMenu, MENU_TYPES, SUB_MENUS } from '@constants/subMenu';

export const isVideoPage = () =>
  /\/app\/experience-insights\//.test(window.location.pathname || '');

export const isAdInstantFilter = () => {
  return (
    /\/app\/ad-insights\//.test(window.location.pathname || '') ||
    window.location.pathname.endsWith('alerts4/adi')
  );
};

export const isPrecisionPage = () => {
  return /\/app\/precision\//.test(window.location.pathname || '');
};

export const isProfilePage = () => {
  return /\/app\/pulse\/settings\/personal_info/.test(window.location.pathname || '');
};

export const isViewerInsights = () => {
  return /\/app\/viewer-insights\//.test(window.location.pathname || '');
};

export const isSocialInsights = () => {
  return /\/app\/viewer-insights\/social\//.test(window.location.pathname);
};

export const isSocialInsightsDashboard = () => {
  return window.location.pathname.startsWith('/app/viewer-insights/social/dashboard');
};

export const isAppManager = () => {
  return /\/app\/appmanager(?!\/ecoIntegration)/.test(window.location.pathname || '');
};

export const isEcoIntegration = () => {
  return /\/app\/appmanager\/ecoIntegration/.test(window.location.pathname || '');
};

export const isEICustomDashboards = () => {
  return /\/app\/custom-dashboards/.test(window.location.pathname || '');
};

export const isAIRoadMap = () => {
  return /\/app\/experience-insights\/dashboard\/ai-roadmap/.test(window.location.pathname || '');
};

export const isAppInsights = () => {
  return (
    /\/app\/app-insights\//.test(window.location.pathname || '') ||
    /\/app\/activation\//.test(window.location.pathname || '')
  );
};

export const stringifyUrl = (body?: object): string => {
  if (!body) {
    return '';
  }
  const keys = Object.keys(body);
  return keys
    .map(key => {
      const value = body[key];
      if (value === undefined) {
        return '';
      }
      if (value === null) {
        return encodeURIComponent(`${key}=null`);
      }
      if (Array.isArray(value)) {
        return encodeURIComponent(
          value.map(v => (key === undefined ? '' : `${key}=${v}`)).join('&'),
        );
      }
      return `${key}=${value}`;
    })
    .join('&');
};

export const hasSubMenu = (): boolean =>
  Object.keys(SUB_MENUS).some(
    type =>
      !!SUB_MENUS[type] &&
      SUB_MENUS[type].some(menu => window.location.pathname.startsWith(menu.url)),
  );

const urlExactMap = [
  // executive email 1.0: /app/pulse/exec_email
  // executive email 2.0: /app/pulse/exec_email/new
  '/app/pulse/exec_email',
  '/app/pulse/users',
  // filter management /app/pulse/filters
  // filter subcategory /app/pulse/filters/editcat
  '/app/pulse/filters',
];
export const urlMatch = (pathname: string, url: string): boolean => {
  if (urlExactMap.some(p => pathname.startsWith(p))) {
    return pathname === url;
  }
  return pathname.startsWith(url);
};

export const getSubMenu = (product: PRODUCTS): [MENU_TYPES, SubMenu] | null => {
  let result: [MENU_TYPES, SubMenu] | null = null;
  Object.keys(SUB_MENUS).forEach(menuType => {
    if (SUB_MENUS[menuType]) {
      SUB_MENUS[menuType].forEach(menu => {
        const actualUrl = isFunction(menu.url) ? menu.url(product) : menu.url;
        if (urlMatch(window.location.pathname, actualUrl)) {
          result = [menuType as MENU_TYPES, menu];
        }
      });
    }
  });
  return result;
};
