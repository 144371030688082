import {
  isAdInstantFilter,
  isPrecisionPage,
  isViewerInsights,
  isSocialInsights,
  isProfilePage,
  isSocialInsightsDashboard,
  isAppManager,
  isEICustomDashboards,
  isAppInsights,
  isVideoPage,
  isEcoIntegration,
  isAIRoadMap,
} from '@utils/url';
import { isSIOnly } from '@utils/cookie';
import { openContactDrawerAtom } from '@subApps/navApp/Atoms/HelpAtoms';
import { getDefaultStore } from 'jotai';
import { VIHelpDisplayName, VIHelpUrl, SIHelpDisplayName, SIHelpUrl } from './utils';
import { Route } from '../types';

export enum SETTING_MENU_ITEMS {
  eiQuickGuide = 'eiQuickGuide',
  aiQuickGuide = 'aiQuickGuide',
  eiCustomDashboardsLearningCenter = 'eiCustomDashboardsLearningCenter',
  aiRoadMap = 'aiRoadMap',
  precision = 'precision',
  releaseNotes = 'releaseNotes',
  sensorInstructions = 'sensorInstructions',
  learningCenter = 'learningCenter',
  accessAndDataRetentionPolicy = 'accessAndDataRetentionPolicy',
  dataPrivacyAndGDPR = 'dataPrivacyAndGDPR',
  contactSupport = 'contactSupport',
  viewerInsights = 'viewerInsights',
  socialInsights = 'socialInsights',
  appManagerAbout = 'appManagerAbout',
  touchstone = 'touchstone',
  appManagerBuildSubmission = 'appManagerBuildSubmission',
  appManagerMetadataMapping = 'appManagerMetadataMapping',
  appManagerReports = 'appManagerReports',
  appManagerFAQ = 'appManagerFAQ',
  ecoIntegrationFAQ = 'ecoIntegrationFAQ',
  VIQuickGuide = 'viQuickGuide',
  appInsights = 'appInsights',
  promptAI = 'promptAI',
  ecoIntegrationAbout = 'ecoIntegrationAbout',
}

export const routes: Route[] = [
  {
    id: SETTING_MENU_ITEMS.eiQuickGuide,
    displayName: 'Video Help',
    url: () => {
      window.open(
        '/learning-center/content/ei_application/experience_insights_application.htm',
        '_blank',
      );
    },
    display: () =>
      !(
        isAdInstantFilter() ||
        isPrecisionPage() ||
        isViewerInsights() ||
        isSocialInsights() ||
        isSIOnly() ||
        isAppManager() ||
        isAppInsights() ||
        isEcoIntegration()
      ),
  },
  {
    id: SETTING_MENU_ITEMS.eiCustomDashboardsLearningCenter,
    displayName: 'Custom Dashboard Help',
    url: () => {
      window.open('/learning-center/content/eco/custom_dashboard_eco.html', '_blank');
    },
    display: () => isEICustomDashboards(),
  },
  {
    id: SETTING_MENU_ITEMS.aiQuickGuide,
    displayName: 'Ad Experience Quick Guide',
    url: () => {
      window.open('/learning-center/content/ai_application/ad_insights_application.htm', '_blank');
    },
    display: () => isAdInstantFilter(),
  },
  {
    id: SETTING_MENU_ITEMS.aiRoadMap,
    displayName: 'AI Roadmap',
    url: () => {
      window.open('/learning-center/content/ei_application/ea-features/ai_roadmap.html', '_blank');
    },
    display: () => isAIRoadMap(),
  },
  {
    id: SETTING_MENU_ITEMS.viewerInsights,
    displayName: VIHelpDisplayName,
    url: () => {
      window.open(VIHelpUrl(), '_blank');
    },
    display: () => isViewerInsights() && !isSocialInsights(),
  },
  {
    id: SETTING_MENU_ITEMS.socialInsights,
    displayName: () => SIHelpDisplayName() || '',
    url: () => {
      window.open(SIHelpUrl(), '_blank');
    },
    display: () => !!(isSocialInsights() && SIHelpDisplayName()),
  },
  {
    id: `${SETTING_MENU_ITEMS.socialInsights}_dashboard_index`,
    displayName: 'Social Insights Dashboard Help',
    url: () => {
      window.open(
        '/learning-center/content/si_application/dashboards/social_dashboard.htm',
        '_blank',
      );
    },
    display: () => isSocialInsightsDashboard(),
  },
  {
    id: `${SETTING_MENU_ITEMS.socialInsights}_help_index`,
    displayName: 'Social Insights Help',
    url: () => {
      window.open(
        '/learning-center/content/si_application/social_insights_application.htm',
        '_blank',
      );
    },
    display: () => isSocialInsights(),
  },
  {
    id: SETTING_MENU_ITEMS.precision,
    displayName: 'Precision Help',
    url: () => {
      window.open(
        '/learning-center/content/precision_application/precision_application.htm',
        '_blank',
      );
    },
    display: () => isPrecisionPage(),
  },
  {
    id: SETTING_MENU_ITEMS.VIQuickGuide,
    displayName: 'Viewer Insights Quick Guide',
    url: () => {
      window.open(
        '/learning-center/content/vi_application/quick_guide/viewer-insights-quick-guide.htm',
        '_blank',
      );
    },
    display: () => isViewerInsights(),
  },
  {
    id: SETTING_MENU_ITEMS.ecoIntegrationAbout,
    displayName: 'About ECO Integration',
    url: () => {
      window.open(
        '/learning-center/content/sensor_developer_center/tools/eco_integration/eco_integration_help.htm',
        '_blank',
      );
    },
    display: () => isEcoIntegration(),
  },
  {
    id: SETTING_MENU_ITEMS.appManagerAbout,
    displayName: 'About Video Integration',
    url: () => {
      window.open(
        '/learning-center/content/sensor_developer_center/tools/application_manager_v2/application_manager_application.htm',
        '_blank',
      );
    },
    display: () => isAppManager(),
  },
  {
    id: SETTING_MENU_ITEMS.appManagerBuildSubmission,
    displayName: 'Build Submissions',
    url: () => {
      window.open(
        '/learning-center/content/sensor_developer_center/tools/application_manager_v2/dashboards/apps_dashboard.htm',
        '_blank',
      );
    },
    display: () => isAppManager(),
  },
  {
    id: SETTING_MENU_ITEMS.appManagerMetadataMapping,
    displayName: 'Metadata Mapping',
    url: () => {
      window.open(
        '/learning-center/content/sensor_developer_center/tools/application_manager_v2/dashboards/metadata_dashboard.htm',
        '_blank',
      );
    },
    display: () => isAppManager(),
  },
  {
    id: SETTING_MENU_ITEMS.appManagerReports,
    displayName: 'Integration Reports',
    url: () => {
      window.open(
        '/learning-center/content/sensor_developer_center/tools/application_manager_v2/dashboards/reports_dashboard.htm',
        '_blank',
      );
    },
    display: () => isAppManager(),
  },
  {
    id: SETTING_MENU_ITEMS.touchstone,
    displayName: 'About Touchstone 2.0',
    url: () => {
      window.open(
        '/learning-center/content/sensor_developer_center/tools/touchstone_v2/touchstone_2_help.htm',
        '_blank',
      );
    },
    display: () => isAppManager(),
  },
  {
    id: SETTING_MENU_ITEMS.appManagerFAQ,
    displayName: 'FAQ',
    url: () => {
      window.open(
        '/learning-center/content/sensor_developer_center/tools/application_manager_v2/faq/application_manager_faq.htm',
        '_blank',
      );
    },
    display: () => isAppManager(),
  },
  {
    id: SETTING_MENU_ITEMS.promptAI,
    displayName: 'PromptAI Help',
    url: () => {
      window.open('/learning-center/content/promptai/promptai_intro.htm', '_blank');
    },
    display: () => isAppInsights() || isAdInstantFilter() || isVideoPage(),
  },
  {
    id: SETTING_MENU_ITEMS.appInsights,
    displayName: 'ECO Help',
    url: () => {
      window.open('/learning-center/content/eco/eco_help.htm', '_blank');
    },
    display: () => isAppInsights(),
  },
  {
    id: SETTING_MENU_ITEMS.releaseNotes,
    displayName: () => {
      if (isViewerInsights()) {
        return `Viewer Insights Release Notes`;
      }
      if (isAppInsights()) {
        return `What's New in ECO`;
      }
      if (isVideoPage()) {
        return `What's New in Video`;
      }
      if (isEcoIntegration()) {
        return `What's New in ECO Integration`;
      }
      return `Release Notes`;
    },
    url: () => {
      let url = '/learning-center/content/main/whats_new_conviva.htm';

      if (isSocialInsights()) {
        url = '/learning-center/content/si_application/whats_new_social_insights.htm';
      } else if (isViewerInsights()) {
        url = '/learning-center/content/vi_application/whats_new_viewer_insights.htm';
      } else if (isAppInsights()) {
        url = '/learning-center/content/eco/whats_new_eco.htm';
      } else if (isVideoPage()) {
        url = '/learning-center/content/ei_application/whats_new_experience_insights.htm';
      } else if (isEcoIntegration()) {
        url =
          '/learning-center/content/sensor_developer_center/tools/eco_integration/whats_new_eco_integration.htm';
      }
      window.open(url, '_blank');
    },
  },
  {
    id: SETTING_MENU_ITEMS.ecoIntegrationFAQ,
    displayName: 'FAQ',
    url: () => {
      window.open(
        '/learning-center/content/sensor_developer_center/tools/eco_integration/eco_integration_faq.htm',
        '_blank',
      );
    },
    display: () => isEcoIntegration(),
  },
  {
    id: SETTING_MENU_ITEMS.sensorInstructions,
    displayName: 'Sensor Instructions',
    url: () => {
      window.open(
        '/learning-center/content/sensor_developer_center/sensor_integration/overview/overview_stream_sensor.htm',
        '_blank',
      );
    },
    display: () => isProfilePage(),
  },
  {
    id: SETTING_MENU_ITEMS.learningCenter,
    displayName: 'Learning Center',
    url: () => {
      window.open('/learning-center/content/main/main.htm', '_blank');
    },
    display: () => isProfilePage(),
  },
  {
    id: SETTING_MENU_ITEMS.accessAndDataRetentionPolicy,
    displayName: 'Access and Data Retention Policy',
    url: () => {
      window.open('/learning-center/content/a_common/retention_policy_2720465.html', '_blank');
    },
  },
  {
    id: SETTING_MENU_ITEMS.dataPrivacyAndGDPR,
    displayName: 'Data Privacy and GDPR',
    url: () => {
      window.open('https://www.conviva.com/legal/#GDPR', '_blank');
    },
  },
  {
    id: SETTING_MENU_ITEMS.contactSupport,
    displayName: 'Contact Support',
    url: () => {
      const defaultStore = getDefaultStore();
      defaultStore.get(openContactDrawerAtom);
      defaultStore.set(openContactDrawerAtom, true);
    },
  },
];
