import React from 'react';
import useSWR from 'swr';
import { ManualAlertEvent } from '@subApps/navApp/hooks/useData/useAlerts/typed';
import { pulse5Fetcher } from '@subApps/navApp/apis/request';

export default (
  query: {
    limit?: number;
    type: 0 | 2; // 0 for EI and 2 for AI
  },
  shouldFetch: boolean = true,
) => {
  const memoQuery = React.useMemo(
    () => `/alerts/manual/recent?type=${query.type}&limit=${query.limit}`,
    [JSON.stringify(query)],
  );

  const { data = [], error } = useSWR<ManualAlertEvent[]>(
    shouldFetch ? memoQuery : null,
    url => pulse5Fetcher(url),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryCount: 3,
    },
  );

  return {
    data,
    error,
    loading: !data,
  };
};
