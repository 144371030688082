import styled from 'styled-components';
import { Menu as BaseMenu } from 'antd';

const SPACE = 20;
const LEFT = 5;
const TITLE_HEIGHT = 40;
const ITEM_HEIGHT = 28;

export const Item = styled.div<{ $level: number }>`
  font-size: 16px;
  font-weight: 400;
  padding-left: ${props => `${(props.$level + 1) * SPACE + LEFT}px`};
`;

export const DisplayName = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    font-size: 35px;
    flex: 0 0 auto;
  }

  & > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const Header = styled.div<{ $open: boolean; $prefix: boolean; $level: number }>`
  display: flex;
  flex-direction: column;
  height: ${props => (props.$open || props.$prefix ? '100%' : `${TITLE_HEIGHT}px`)};
  color: inherit;
  padding-left: ${props => `${props.$level * SPACE + LEFT}px`};
`;

export const GroupHeader = styled.div<{ $level: number }>`
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  margin: 0 4px;
  padding-left: ${props => `${props.$level * SPACE + LEFT}px`};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Icon = styled.div`
  font-size: 24px;
  display: flex;
`;

export const TitleLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const Description = styled.div`
  width: calc(100% - 50px);
  margin: 0 0 10px 20px;
  white-space: normal;
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
  color: #5a5a5a;
`;
export const Prefix = styled(Description)`
  margin: 15px 0 5px 20px;
`;

export const StyledMenu = styled(BaseMenu)`
  &.apphub-menu {
    &.apphub-menu-root {
      & > .apphub-menu-submenu {
        & > ul {
          margin-bottom: 12px;
        }
      }
    }
  }

  .apphub-menu-submenu-inline {
    &.apphub-menu-submenu-selected {
      .apphub-menu-submenu-title {
        background-color: #e6f1ff;
      }
    }
    .apphub-menu-submenu-title {
      padding-left: 12px !important;
      height: 100% !important;
      line-height: ${TITLE_HEIGHT}px !important;
    }

    .apphub-menu-inline {
      .apphub-menu-item {
        padding: 0 !important;
        height: ${ITEM_HEIGHT}px;
        line-height: ${ITEM_HEIGHT}px;
      }
    }

    .apphub-menu-item-group {
      .apphub-menu-item-group-title {
        padding: 0 !important;
        margin-left: 0;
      }
      .apphub-menu-item-group-list {
        ${Item} {
          font-weight: 400;
        }
      }
    }
  }
`;
