import React from 'react';
import { ConfigProvider } from 'antd';
import { useMenuItems } from '@subApps/navApp/components/Navigation/components/Menu/useMenuItems';
import { PRODUCT_URL_PREFIX } from '@constants/products';
import { StyledMenu } from './style/styled';
import { NavMenuContext } from '../../NavMenuContext';
import { themeToken } from './style/themeToken';
import type { MenuItem, MenuProps } from './type';

function getItemByRule(
  rule: (item: MenuItem) => boolean,
  menuItems: MenuItem[] = [],
  path: string[] = [],
) {
  for (const item of menuItems) {
    if (rule(item)) {
      return [item, [...path, item.id]];
    }
    if ('children' in item) {
      const found = getItemByRule(rule, item.children, [...path, item.id]);
      if (found[0]) {
        return found;
      }
    }
  }
  return [undefined, []];
}

export const Menu = React.memo(({ config, loading, onEarlyReturnClick, style }: MenuProps) => {
  const {
    openKeys,
    selectedKeys,
    pathName,
    setSelectedKeys,
    setProduct,
    onOpenChange,
    onClick,
    setShowNoAccess,
  } = React.useContext(NavMenuContext);

  // set active menu item
  React.useEffect(() => {
    const items = config.groups?.flat();
    // set select keys
    const [item, path] = getItemByRule(i => {
      if ('url' in i) {
        // using startsWith to check the path
        if (i.ignoreExactMatch) return i.url.startsWith(pathName);
        return i.url === pathName;
      }
      return false;
    }, items);
    if (item && !item.ghost) {
      setSelectedKeys(path);
      setProduct(item.category);
    } else {
      const [productItem] = getItemByRule(i => {
        return pathName.startsWith(PRODUCT_URL_PREFIX[i.category]);
      }, items);
      if (productItem) {
        setSelectedKeys(productItem.category);
        setProduct(productItem.category);
      }
    }
  }, [config, pathName]);

  const items = useMenuItems(config, openKeys, loading);

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: themeToken,
        },
      }}
    >
      <StyledMenu
        style={style}
        mode="inline"
        onClick={({ key, keyPath, domEvent }) => {
          domEvent.preventDefault();
          const [clickItem] = getItemByRule(i => i.id === key, config.groups?.flat());
          if (clickItem && !onEarlyReturnClick?.(clickItem, keyPath)) {
            onClick(clickItem, keyPath);
          }
        }}
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        onOpenChange={keys => {
          if (keys.includes(config.product) && config.disabled) {
            setShowNoAccess(true);
            return;
          }
          if (!openKeys.includes(config.product)) {
            // open other product, close itself
            onOpenChange([config.product]);
          } else {
            onOpenChange(keys);
          }
        }}
        items={items}
      />
    </ConfigProvider>
  );
});
