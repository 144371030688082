import { ENDPOINTS, ENDPOINT_DOMAINS } from '@constants/request';
import { stringifyUrl } from '@utils/url';
import { isNumber } from 'lodash';
import { message } from 'antd';
import { isSIOnly, clearSIOnlyFlag } from '@utils/cookie';
import { pulse5Mock } from './si-only-mock';

export const DEFAULT_ERROR = 'Server error. Please retry or contact support.';

// handle generic API errors
const handleError = response => {
  // Cookie 'SIO' is used to inspect whether the user is SI only customer.
  // When request response '401 Unauthorized', clear it to avoid accidents.
  if (response.status === 401) {
    clearSIOnlyFlag();
  }

  // Case 1: It is cors. So it will send a OPTIONS query, if fails, it will go to /login
  // Case 2: The auth hasn't access. It also will navigate to /login
  if (
    window.__POWERED_BY_QIANKUN__ &&
    (!response.status || (response.status >= 400 && response.status < 500))
  ) {
    window.location.href = `/login?next=${window.location.pathname}${window.location.search}`;
    return response.status;
  }

  if (response.status === 404) throw new Error('404, Not found');
  if (response.status === 500) throw new Error('500, internal server error');

  // For any other server error
  throw new Error('Something went wrong');
};

// TODO: differentiate 401 and 404
const handle40X = (e: any) => {
  // Cookie 'SIO' is used to inspect whether the user is SI only customer.
  // When request response '401 Unauthorized', clear it to avoid accidents.
  if (e?.response?.status === 401) {
    clearSIOnlyFlag();
  }

  // Case 1:
  // It is cors. So it will send a OPTIONS query, if fails, it will go to /login
  // Case 2:
  // The auth hasn't access. It also will navigate to /login
  if (
    window.__POWERED_BY_QIANKUN__ &&
    (!e.response || (e.response && e.response.status >= 400 && e.response.status < 500))
  ) {
    window.location.href = `/login?next=${window.location.pathname}${window.location.search}`;
    return e.response.status;
  }
};

export enum REQUEST_METHODS {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export const pulse5Fetcher = async (url, customerId = null) => {
  if (isSIOnly()) {
    try {
      return pulse5Mock(url);
    } catch {
      /* */
    }
  }

  try {
    const headers = {
      accept: '*/*',
      'X-Requested-With': 'XMLHttpRequest',
      'cache-control': 'no-cache',
      'content-type': 'application/json',
    };

    if (customerId) {
      headers['X-cid'] = btoa(customerId);
    }

    const response = await fetch(`${ENDPOINT_DOMAINS[ENDPOINTS.pulse5]}v1.0${url}`, {
      method: 'GET',
      headers,
      mode: 'cors',
      credentials: 'include',
    });

    if (response.ok) {
      // customer id mismatch, reload page
      if (response.status === 205) {
        window.location.reload();
        return null;
      }
      return response.json();
    }
    return handleError(response);
  } catch (error) {
    console.error('pulse5Fetcher error', error);
    message.error(DEFAULT_ERROR);
  }
};

export const pulse5Post = async (url, payload) => {
  try {
    const response = await fetch(`${ENDPOINT_DOMAINS[ENDPOINTS.pulse5]}v1.0${url}`, {
      method: REQUEST_METHODS.POST,
      headers: {
        accept: '*/*',
        'X-Requested-With': 'XMLHttpRequest',
        'cache-control': 'no-cache',
        'content-type': 'application/json',
      },
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      return response.json();
    }
    return handleError(response);
  } catch (error) {
    console.error('pulse5Post error', error);
    message.error(DEFAULT_ERROR);
  }
};

export const pulse4Fetcher = [
  REQUEST_METHODS.GET,
  REQUEST_METHODS.POST,
  REQUEST_METHODS.PUT,
  REQUEST_METHODS.DELETE,
].reduce((result, method: REQUEST_METHODS) => {
  result[method] = (url, query, opt = {}) =>
    new Promise((resolve, reject) => {
      let optCopy = {
        headers: {
          accept: '*/*',
          'X-Requested-With': 'XMLHttpRequest',
          'accept-language': 'en-US,en;q=0.9,zh-CN;q=0.8,zh;q=0.7',
          'cache-control': 'no-cache',
          'content-type': 'application/json',
        },
        mode: 'cors',
        credentials: 'include',
      };

      if (opt.headers) {
        const { headers, ...rest } = opt;
        optCopy = {
          ...optCopy,
          headers: {
            ...optCopy.headers,
            ...headers,
          },
          ...rest,
        };
      } else {
        optCopy = {
          ...optCopy,
          ...opt,
        };
      }

      fetch(url, {
        method,
        ...optCopy,
        body:
          optCopy.headers && optCopy.headers['content-type'].includes('application/json')
            ? JSON.stringify(query)
            : stringifyUrl(query),
      })
        .then(response => {
          if (isNumber(handle40X({ response }))) {
            reject(new Error(`You can't access.`));
          }
          return resolve(response.json());
        })
        .catch(e => reject(e));
    });
  return result;
}, {}) as {
  [key in keyof typeof REQUEST_METHODS]: (url: string, query: Object, options: any) => Promise<any>;
};

type ParamType<T> = T extends (...args: infer P) => void ? P : never;

export const fetcher = async <T = any>(...args: ParamType<typeof fetch>) => {
  const [input, init] = args;
  const response = await fetch(input, {
    credentials: 'include',
    ...(init || {}),
    headers: {
      'Content-Type': 'application/json',
      ...(init?.headers || {}),
    },
  });

  if (response.status === 401) {
    // Cookie 'SIO' is used to inspect whether the user is SI only customer.
    // When request response '401 Unauthorized', clear it to avoid accidents.
    clearSIOnlyFlag();
    window.location.href = `/login`;
  }

  const responseBody = await response.json();

  if (!response.ok) {
    throw new Error(responseBody?.message);
  }

  return responseBody as T;
};
