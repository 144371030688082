import useSWR from 'swr';
import { pulse5Fetcher } from '@subApps/navApp/apis/request';

const pulse5DashboardsFetcher = url => {
  return pulse5Fetcher(url);
};

export default (product, shouldFetch: boolean = true) => {
  const { data, error } = useSWR(
    shouldFetch ? `/dashboards/pulse5?mode=${product}` : null,
    pulse5DashboardsFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
  return {
    data,
    error,
    loading: !data,
  };
};
