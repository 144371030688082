import React from 'react';
import classnames from 'classnames';
import { PRODUCTS } from '@constants/products';
import { useShowMenu } from '@subApps/navApp/hooks/useGlobal';
import { HomeIcon } from '@subApps/common/components/Icons';
import { WrapperItem } from './styled';

interface MenuItemProps {
  className?: string;
  id?: string;
  url?: string;
  target?: string;
  isActive?: boolean;
  name: string;
  onClick?: ((product: PRODUCTS) => boolean) | ((product: string) => boolean);
  showIcon?: boolean;
}

const MenuItem = ({
  className,
  id,
  url = '',
  target,
  isActive = false,
  name,
  onClick,
  showIcon = false,
}: MenuItemProps) => {
  const { onStateChange: setShowMenu } = useShowMenu();
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const stopHere = id && onClick && !onClick(id);
    if (stopHere || !url) {
      return;
    }
    if (target === '_blank') {
      window.open(url, target);
    } else {
      window.history.pushState(null, url, url);
    }
    setShowMenu(false);
  };

  return (
    <WrapperItem
      className={classnames(className, {
        active: isActive,
      })}
      onClick={handleClick}
      title={name}
    >
      <span>{name}</span>
      {showIcon && <HomeIcon />}
    </WrapperItem>
  );
};

export default MenuItem;
