import useSWR, { mutate } from 'swr';

const swrCacheKey = 'GLOBAL/STATE';

type OnStateChange<T> = (value: T) => void;
type UseGlobal<T> = () => {
  data: T;
  onStateChange: OnStateChange<T>;
};

export default function createHook<T>(key: string, value: T): UseGlobal<T> {
  let state: {
    value: T;
  } = {
    value,
  };
  const getState = async (): Promise<{ value: T }> => state;
  const swrKey = `${swrCacheKey}/${key}`;
  return () => {
    const { data } = useSWR<{ value: T }>(swrKey, getState);
    const onStateChange: OnStateChange<T> = _value => {
      state = { ...state, value: _value };
      // Once the state has been changed, will tell all SWRs with this key to revalidate
      mutate(swrKey);
    };
    return { data: data ? data.value : value, onStateChange };
  };
}
