import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import * as Styled from '../style/styled';

type HeaderProps = {
  open: boolean;
  title: React.ReactNode;
  level: number;
  icon?: React.ReactNode;
  loading?: boolean;
  prefix?: string;
  suffix?: string;
};

const Header = ({ open, title, icon, prefix, suffix, loading, level }: HeaderProps) => {
  return (
    <Styled.Header $open={open} $prefix={!!prefix} $level={level}>
      {prefix && <Styled.Prefix>{prefix}</Styled.Prefix>}
      <Styled.Title>
        {icon && <Styled.Icon>{icon}</Styled.Icon>}
        <Styled.TitleLabel>{title}</Styled.TitleLabel>
        {loading && <LoadingOutlined />}
      </Styled.Title>
      {suffix && <Styled.Description>{suffix}</Styled.Description>}
    </Styled.Header>
  );
};

export const GroupHeader = ({ title, level }: { title: React.ReactNode; level: number }) => (
  <Styled.GroupHeader $level={level}>{title}</Styled.GroupHeader>
);

export default Header;
