const pulse5MockData = new Map<string, any>([
  ['/feature_flags', {}],
  ['/user/profiles', []],
  ['/customerCheck', []],
  [
    '/nav/legacy',
    {
      profile: [
        {
          name: 'Change Password',
        },
      ],
      menu: {
        ei: [
          {
            children: [
              {
                name: 'Quality',
              },
            ],
          },
        ],
      },
      application: [
        {
          link: 'https://pulse.conviva.com/learning-center/content/main/main.htm',
          name: 'Community',
        },
        { link: 'https://touchstone.conviva.com/', name: 'Touchstone' },
        { link: 'https://plm1.conviva.com', name: 'Application Manager' },
      ],
      ei_setting: [],
      ai_setting: [],
    },
  ],
  ['/default-landing-app', { app: 'si' }],
  [
    '/user/profile',
    {
      customer: {
        name: '',
        timezone: '',
      },
      customerId: NaN,
      id: NaN,
      role: '',
      userId: NaN,
      username: '',
    },
  ],
]);

export const pulse5Mock = (query: string) => {
  if (pulse5MockData.has(query)) return pulse5MockData.get(query);

  throw new Error('mock query not found');
};
