import styled from 'styled-components';
import { colorMap } from '@utils/colorMap';
import { Menus } from '@subApps/navApp/components/common';

export default '';

export const Wrapper = styled.div`
  width: 260px;
  background: ${() => colorMap.backgroundColor};
  margin-right: 5px;
  display: flex;
  flex-direction: column;
`;

export const MenuItem = styled(Menus.MenuItem)`
  width: 100%;
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  line-height: 20px;
  font-size: 18px;
  &::before {
    left: 0;
    right: 0;
  }
  &.active {
    color: ${() => colorMap.primarySelected};
    font-weight: 600;
  }
`;
