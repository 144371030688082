import React from 'react';
import { Dropdown, Space } from 'antd';
import Icon from '@ant-design/icons';
import { useNav } from '@subApps/navApp/hooks/useData';
import { getIcon, ICON_NAMES } from '@resources/icons';
import { IconButton } from '@subApps/common/components';
import useItems from '../hooks/useItems';
import useApply from '../hooks/useApply';
import { Route } from '../types';
import { getHelpLinks } from './utils';
import { routes } from './constants';

const Help = ({ compact = false }: { compact?: boolean }) => {
  const [shouldUpdate, renderer] = React.useState<null | {}>(null);
  const { data: nav } = useNav();
  const cacheRoutes = React.useMemo(() => {
    const { name, path } = getHelpLinks(nav);
    let displayName = name;
    if (name === 'App Experience') {
      displayName = 'Trends ';
    }
    const newRoutes: Route[] = [];
    newRoutes.push({
      id: 'custom_help',
      displayName: displayName || 'Help',
      url: () => window.open(path, '_blank'),
      display: () => !['', '/help'].includes(path),
    });

    return [...newRoutes, ...routes];
  }, [shouldUpdate, nav]);

  const items = useItems(cacheRoutes);

  const handleApply = useApply(cacheRoutes);

  const handleURLHasChange = () => {
    renderer({});
  };

  React.useEffect(() => {
    window.addEventListener('popstate', handleURLHasChange);
    return () => {
      window.removeEventListener('popstate', handleURLHasChange);
    };
  }, []);

  return (
    <Dropdown menu={{ items, onClick: handleApply }} trigger={['click']}>
      <Space>
        <IconButton>
          <Icon component={getIcon(ICON_NAMES.help)} />
        </IconButton>
        {compact && <span>Help</span>}
      </Space>
    </Dropdown>
  );
};

export default React.memo(Help);
