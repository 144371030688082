import React from 'react';
import { get } from 'lodash';
import { PRODUCTS, PRODUCT_DISPLAY_NAMES_NEW_NAV } from '@constants/products';
import ECOIcon from '@resources/icons/product/EcoServer.svg';
import { ECO_SERVER_ACCESS } from '@subApps/navApp/hooks/useData/usePulse5Features/featureNames';
import { Menu } from '../../components/Menu';

const config = {
  product: PRODUCTS.ECO_SERVER,
  icon: <ECOIcon />,
  displayName: PRODUCT_DISPLAY_NAMES_NEW_NAV[PRODUCTS.ECO_SERVER],
  groups: [
    [
      {
        id: 'eco-alert',
        category: PRODUCTS.ECO_SERVER,
        displayName: 'Experience Alerts',
        url: '/app/eco-server/dashboard/ai-alerts',
        valid: ({ features }) => get(features, ECO_SERVER_ACCESS, false),
      },
    ],
  ],
};

const ECO = () => {
  return <Menu config={config} />;
};

export default ECO;
