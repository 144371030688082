import { useState, useEffect, useMemo } from 'react';
import useSWR from 'swr';
import { ENDPOINT_DOMAINS } from '@constants/request';
import { PRODUCT_URL_PREFIX } from '@constants/products';
import { fetcher } from '@subApps/navApp/apis/request';
import { checkAppAccessByCookie } from '@utils/cookie';
import { useNav } from '@subApps/navApp/hooks/useData';

const SI_MENU_MAP = {
  Leaderboard: '/leaderboard',
  Accounts: '/accounts',
  Analytics: '/analytics',
  Campaigns: '/campaigns',
  'Content Tags': '/contenttags',
  Settings: '/settings',
  Admin: '/admin',
};

interface SIMenu {
  items: { _id: number; text: string; aclString: string }[];
}

export default (permission?: boolean) => {
  const endpoint = ENDPOINT_DOMAINS.si;
  const { data, error, isValidating } = useSWR<SIMenu, Error>(
    permission && endpoint ? `${endpoint}/api/users/me/menu` : null,
    fetcher,
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    },
  );

  const siMenu = useMemo(() => {
    const menu = data?.items
      ?.filter(it => SI_MENU_MAP[it.text])
      ?.map(it => ({
        id: it._id,
        displayName: it.text,
        url: `${PRODUCT_URL_PREFIX.SI}${SI_MENU_MAP[it.text]}`,
      }));

    if (data?.items.find(it => it.aclString === 'dashboard.view.customized')) {
      menu?.unshift({ id: -1, displayName: 'Dashboard', url: PRODUCT_URL_PREFIX.CUDA_SI });
    }
    return menu;
  }, [data]);

  return {
    data: siMenu,
    error,
    loading: isValidating,
  };
};

export const useSIStrictPermission = () => {
  const { data: nav } = useNav();
  const SIPermission =
    nav?.application.some(m => m.link === '/app_switch/si') ||
    checkAppAccessByCookie('Social Insights');
  const [loading, setLoading] = useState(false);
  const [permission, setPermission] = useState(false);
  const [menus, setMenus] = useState<any>(null);

  useEffect(() => {
    if (!SIPermission) {
      return;
    }
    setLoading(true);
    const getMenus = async () => {
      const data = await fetch(`${ENDPOINT_DOMAINS.si}/api/users/me/menu`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const means = await data.json();
      return means;
    };
    getMenus()
      .then(res => {
        setPermission(true);
        setMenus(res);
      })
      .catch(() => setPermission(false))
      .finally(() => setLoading(false));
  }, [SIPermission]);

  return { loading, permission, menus };
};

export const useSIDashboardPermission = () => {
  // Check SI permission first.
  const SIPermission = checkAppAccessByCookie('Social Insights');
  const [loading, setLoading] = useState(true);
  const [permission, setPermission] = useState(false);

  useEffect(() => {
    if (!SIPermission) return;

    const controller = new AbortController();
    (async () => {
      try {
        setLoading(true);

        await new Promise((r, j) => {
          fetch(`${ENDPOINT_DOMAINS.si}/api/users/me/menu`, {
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            signal: controller.signal,
          })
            .then(response => {
              if (response.ok) {
                return response.json();
              }
            })
            .then(data => {
              if (data?.items?.find(it => it.aclString === 'dashboard.view.customized')) {
                setPermission(true);
              }
            })
            .finally(() => {
              r(undefined);
            });

          // Query timeout 15000 ms.
          setTimeout(() => {
            j(new Error('Timeout'));
            controller.abort();
          }, 15000);
        });
      } catch {
        //
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (!SIPermission)
    return {
      loading: false,
      permission: false,
    };

  return {
    loading,
    permission,
  };
};
