import React from 'react';
import { Dropdown } from 'antd';
import Icon from '@ant-design/icons';
import User from '@subApps/navApp/hooks/useData/useUser/user.model';
import { useUser, useNav } from '@subApps/navApp/hooks/useData';
import { useProduct } from '@subApps/navApp/hooks/useGlobal';
import { getIcon, ICON_NAMES } from '@resources/icons';
import { IconButton } from '@subApps/common/components';
import AlertBellOverlay from './AlertBellOverlay';
import APPAlertBellOverlay from './APPAlertBellOverlay';

const AlertBell = () => {
  const { data: user } = useUser();
  const { data: navData } = useNav();
  const { data: product } = useProduct();

  const isApp = product === 'APP';
  const AlertBellOverlayMemo = isApp ? <APPAlertBellOverlay /> : <AlertBellOverlay />;

  if (user?.isCustomerService() || user?.isDeveloper() || User.isViewerOnly(navData)) return null;

  return (
    <Dropdown overlay={AlertBellOverlayMemo} trigger={['click']}>
      <IconButton>
        <Icon component={getIcon(ICON_NAMES.notification)} />
      </IconButton>
    </Dropdown>
  );
};

export default React.memo(AlertBell);
