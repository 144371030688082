import React from 'react';
import Media from 'react-media';
import {
  PRODUCT_DISPLAY_NAMES_NEW_NAV,
  PRODUCT_DISPLAY_NAMES_TOOLTIP,
  PRODUCT_URL_PREFIX,
} from '@constants/products';
import PreviewImage from '@subApps/common/components/PreviewImage';
import { useProduct } from '@subApps/navApp/hooks/useGlobal';
import { useNav } from '@subApps/navApp/hooks/useData';
import { getImage, IMAGE_NAMES } from '@resources/images';
import User from '@subApps/navApp/hooks/useData/useUser/user.model';

import { Wrapper, Left, LogoLink, ProductLabel } from './styled';

import MenuButton from './MenuButton';
import RightSection from './RightSection';
import MidSection from './MidSection';
import CompactSection from './CompactSection';

const Header = () => {
  const { data: product } = useProduct();
  const { data: nav } = useNav();
  const isViewerOnly = User.isViewerOnly(nav);

  // IF-2869
  const logoHref = isViewerOnly ? '/app/pulse/subscribers' : PRODUCT_URL_PREFIX[product] || '/';

  const productName = PRODUCT_DISPLAY_NAMES_NEW_NAV[product];

  const header = (
    <Media query="(max-width: 480px)">
      {matches => (
        <>
          <LogoLink href={logoHref} className="color-critical">
            <PreviewImage src={getImage('desktop', IMAGE_NAMES.convivaLogo)} alt="Conviva Logo" />
          </LogoLink>
          {productName && !matches && (
            <ProductLabel title={PRODUCT_DISPLAY_NAMES_TOOLTIP[product] ?? ''}>
              {productName}
            </ProductLabel>
          )}
        </>
      )}
    </Media>
  );

  return (
    <Wrapper>
      <Left>
        <MenuButton />
        {header}
      </Left>
      <Media query="(max-width: 480px)">
        {matches => {
          if (matches) {
            return <CompactSection />;
          }
          return (
            <>
              <MidSection />
              <RightSection />
            </>
          );
        }}
      </Media>
    </Wrapper>
  );
};

export default Header;
