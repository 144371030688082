import React from 'react';
import { get } from 'lodash';
import { PRODUCT_URL_PREFIX, PRODUCTS } from '@constants/products';
import { ECO_TRENDS } from '@subApps/navApp/hooks/useData/usePulse5Features/featureNames';
import { useNav, useUser } from '@subApps/navApp/hooks/useData';
import User from '@subApps/navApp/hooks/useData/useUser/user.model';
import type { MenuConfig } from '../../components/Menu/type';

export function useEnableSavedDashboard() {
  const { data: nav } = useNav();
  const { data: user } = useUser();

  const allowedRoles = !user?.isCustomerService() || !user.isDeveloper();
  const isViewerOnly = User.isViewerOnly(nav);

  return allowedRoles && !isViewerOnly;
}

export function useConfigWithSavedDashboard(
  product: PRODUCTS,
  config: MenuConfig,
  insertAfterNodeId?: string,
): MenuConfig {
  const hasSavedDashboard = useEnableSavedDashboard();

  return React.useMemo(() => {
    if (hasSavedDashboard) {
      const saveDashboard = {
        id: `${product}-saved-dashboards`,
        category: product,
        displayName: 'Saved Dashboards',
        url: `${PRODUCT_URL_PREFIX[product]}/dashboard/saved-dashboards`,
        valid: ({ features }) => {
          const isAppEnabled = get(features, ECO_TRENDS, false);
          if (product === PRODUCTS.APP && !isAppEnabled) return false;
          return true;
        },
      };

      if (insertAfterNodeId) {
        const groups = config.groups || [];
        const newGroups = groups.map(group => {
          const index = group.findIndex(item => item.id === insertAfterNodeId);
          if (index !== -1) {
            return [...group.slice(0, index + 1), saveDashboard, ...group.slice(index + 1)];
          }
          return group;
        });

        return {
          ...config,
          groups: newGroups,
        };
      }
      const groups = [...(config.groups || []), [saveDashboard]];
      return {
        ...config,
        groups,
      };
    }
    return config;
  }, [config, hasSavedDashboard, product, insertAfterNodeId]);
}
