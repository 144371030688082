import * as React from 'react';

export interface ErrorBoundaryProps {
  fallbackComponent?: React.ComponentType<any>;
  children?: React.ReactNode;
  onError?: (cause: unknown) => void;
}

interface ErrorBoundaryState {
  error?: Error;
}

const Blank = () => null;

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = { error: undefined };

  static defaultProps = {
    // The default fallback is just to remove a component from the page.
    // That isn't great, but at least it'll prevent a whole page from crashing.
    fallbackComponent: Blank,
  };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, extra: React.ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('Error caught inside ErrorBoundary', { error, extra });
    this.props.onError?.(error);
  }

  render() {
    const { children, fallbackComponent: FallbackComponent } = this.props;
    const { error } = this.state;

    if (error && FallbackComponent) {
      return <FallbackComponent error={error} />;
    }

    return children;
  }
}

export const withErrorBoundary = <P extends {}>(
  component: React.ComponentType<P>,
  fallbackComponent?: React.ComponentType<any>,
  onError?: (cause: unknown) => void,
) => {
  const WrappedComponent = (props: P) => (
    <ErrorBoundary fallbackComponent={fallbackComponent} onError={onError}>
      {React.createElement(component, props)}
    </ErrorBoundary>
  );

  WrappedComponent.displayName = `withErrorBoundary(${component.displayName || component.name})`;

  return WrappedComponent;
};
