import React from 'react';
import styled from 'styled-components';
import Icon from '@ant-design/icons';
import { getIcon, ICON_NAMES } from '@resources/icons';

const Wrapper = styled(Icon)`
  font-size: 10px;
  color: transparent !important;
`;

interface ArrowUpIconProp {
  className?: string;
  style?: React.CSSProperties;
}

const ArrowUpIcon: React.FC<ArrowUpIconProp> = ({ className, style }) => (
  <Wrapper component={getIcon(ICON_NAMES.arrowUp)} className={className} style={style} />
);

export default React.memo(ArrowUpIcon);
