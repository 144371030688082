import { useMemo } from 'react';
import useSWR from 'swr';
import { fetcher } from '@subApps/navApp/apis/request';
import { ENDPOINT_DOMAINS } from '@constants/request';
import { PRODUCT_URL_PREFIX, PRODUCTS } from '@constants/products';

const endpoint = ENDPOINT_DOMAINS.vi;

interface CAMenu {
  displayName: string;
  url?: string;
  submenus?: CAMenu[];
}

const transformFullPath = (menus: CAMenu[]): CAMenu[] => {
  return menus.map(it => {
    const item = { ...it };

    if (item.url) {
      item.url = `${PRODUCT_URL_PREFIX[PRODUCTS.CI]}${item.url}`;
    }

    if (item.submenus?.length) {
      item.submenus = transformFullPath(item.submenus);
    }

    return item;
  });
};

export default (permission?: boolean) => {
  const { data, error, isValidating } = useSWR<CAMenu[], Error>(
    permission ? `${endpoint}/v1/api/menu_list` : null,
    fetcher,
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    },
  );

  const caMenu = useMemo(() => {
    return data && transformFullPath(data);
  }, [data]);

  return {
    data: caMenu,
    error,
    loading: isValidating,
  };
};
