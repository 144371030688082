import { pulse5Post } from '@subApps/navApp/apis/request';

export const contactSupportAPI = async data => {
  try {
    const response = await pulse5Post('/contact-support', data);
    return {
      loading: !response,
      data: response,
    };
  } catch (err) {
    return {
      data: {
        status: 500,
      },
    };
  }
};
