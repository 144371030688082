import React, { useCallback } from 'react';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { ToggleWrapper } from './styled';

const iconStyle = {
  fontSize: '16px',
  color: '#ccc',
};

const Toggle = ({ visible, setVisible }) => {
  const show = useCallback(() => {
    setVisible(true);
  }, [setVisible]);
  const hide = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return (
    <ToggleWrapper onClick={visible ? hide : show}>
      {visible ? <EyeOutlined style={iconStyle} /> : <EyeInvisibleOutlined style={iconStyle} />}
    </ToggleWrapper>
  );
};

export default Toggle;
