import React from 'react';
import { useProduct, useShowMenu } from '@subApps/navApp/hooks/useGlobal';
import { useOnClickOutside, usePathName } from '@subApps/common/hooks';
import { useNav, usePulse5Features, useUser } from '@subApps/navApp/hooks/useData';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import {
  ECO_SERVER_ACCESS,
  MINI_ODP_ACCESS,
} from '@subApps/navApp/hooks/useData/usePulse5Features/featureNames';
import { get } from 'lodash';
import { Video, Ads, APP, Social, Audience, ODP, ECO_SERVER } from './Products';
import { Integration } from './Tools';
import {
  ProductWrapper,
  Wrapper,
  SidebarWrapper,
  ContentWrapper,
  ContactBtnRow,
  CloseContainer,
} from './styled';
import { NavMenuContext } from './NavMenuContext';
import type { MenuItem } from './components/Menu/type';

const contactConviva = () => {
  window.open('/app/pulse/support', '_blank');
};

const Navigation = () => {
  const sidebarRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  const { data: nav } = useNav();
  const { data: user } = useUser();
  const { data: features } = usePulse5Features();
  const { data: product, onStateChange: setProduct } = useProduct();
  const { data: showMenu, onStateChange: setShowMenu } = useShowMenu();

  const [showNoAccess, setShowNoAccess] = React.useState(false);
  const [openKeys, setOpenKeys] = React.useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const { pathName } = usePathName();

  const isEcoServerEnabled = get(features, ECO_SERVER_ACCESS, false);
  const isMiniODPEnabled = get(features, MINI_ODP_ACCESS, false);

  useOnClickOutside(
    sidebarRef,
    () => {
      setShowMenu(false);
    },
    ['#menu-toggle', '#no-access'],
  );

  const onOpenChange = React.useCallback((keyPath: string[]) => {
    setOpenKeys(keyPath);
  }, []);

  const onClick = React.useCallback(
    (item: MenuItem, keyPath: string[]) => {
      if ('valid' in item && item.valid({ nav, user, features })) {
        if (item.target === '_blank' || product !== item.category) {
          window.open(item.url, item.target);
        } else {
          window.history.pushState(null, item.url, item.url);
          setSelectedKeys(keyPath);
          setShowMenu(false);
          setShowNoAccess(false);
          setProduct(item.category);
        }
      } else {
        setShowNoAccess(true);
      }
    },
    [setShowMenu, setProduct, nav, user, features, product],
  );

  const contextValue = React.useMemo(
    () => ({
      openKeys,
      selectedKeys,
      setSelectedKeys,
      onOpenChange,
      onClick,
      pathName,
      setShowNoAccess,
      setProduct,
    }),
    [openKeys, selectedKeys, onOpenChange, onClick, pathName, setShowNoAccess, setProduct],
  );

  if (!nav) {
    return null;
  }
  const closeMessage = () => {
    setShowNoAccess(false);
    setShowMenu(false);
  };

  return (
    <NavMenuContext.Provider value={contextValue}>
      <Wrapper showMenu={showMenu}>
        <SidebarWrapper showMenu={showMenu} ref={sidebarRef}>
          <ProductWrapper>
            <Video />
            <APP />
            {isEcoServerEnabled && <ECO_SERVER />}
            <Ads />
            <Audience />
            <Social />
            <Integration />
            {isMiniODPEnabled && <ODP />}
          </ProductWrapper>
        </SidebarWrapper>
        <ContentWrapper id="no-access" showContent={showNoAccess} ref={contentRef}>
          <CloseContainer onClick={closeMessage} showClose={showNoAccess}>
            <CloseOutlined />
          </CloseContainer>
          <p style={{ textAlign: 'center' }}>
            Please contact your Conviva representative to activate
            <ContactBtnRow>
              <Button onClick={contactConviva}>Contact Conviva</Button>
            </ContactBtnRow>
          </p>
        </ContentWrapper>
      </Wrapper>
    </NavMenuContext.Provider>
  );
};

export default Navigation;
