import styled from 'styled-components';
import { colorMap } from '@utils/colorMap';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${() => colorMap.backgroundColor};
  margin: 0 5px 5px 5px;
  padding: 0 0 20px 0px;
`;

export const TabWrapper = styled.div``;

export const Title = styled.h2`
  font-size: 24px;
  color: ${() => colorMap.text};
  margin: 0;
  padding: 15px 20px 0px 20px;
  font-weight: 400;
`;
