import { useUser } from '@subApps/navApp/hooks/useData';
import { PRODUCT_URL_PREFIX, PRODUCTS } from '@constants/products';
import { useSIDashboardPermission } from '../useSIMenuList';

const genPredefined = (siDashboardPermission: boolean) => ({
  ei: `${PRODUCT_URL_PREFIX[PRODUCTS.EI]}/dashboard/trends`,
  ai: `${PRODUCT_URL_PREFIX[PRODUCTS.AI]}/dashboard/trends`,
  app: `${PRODUCT_URL_PREFIX[PRODUCTS.APP]}/dashboard/app-experience`,
  precision: `${PRODUCT_URL_PREFIX[PRODUCTS.precision]}/precision_policy`,
  vi: PRODUCT_URL_PREFIX.VI,
  si: `${PRODUCT_URL_PREFIX.SI}/${siDashboardPermission ? 'dashboard' : 'analytics'}`,
  ci: `${PRODUCT_URL_PREFIX.CI}/overview_page`,
});

// this hook is not used for redirection from apphub
// it's part of default app API
// SI is using this value via apphub global state
// TODO: check about refactor on SI

export default () => {
  const { loading: userLoading } = useUser();
  const {
    loading: loadingSIDashboardPermission,
    permission: siDashboardPermission,
  } = useSIDashboardPermission();

  if (userLoading || loadingSIDashboardPermission)
    return {
      loading: true,
      data: undefined,
    };

  const PREDEFINED = genPredefined(siDashboardPermission);

  return {
    loading: false,
    data: { ...PREDEFINED },
  };
};
