import React from 'react';
import NewIcon from '@resources/icons/new-icon.svg';
import { Item, DisplayName } from '../style/styled';

const MenuItem = ({
  url,
  displayName,
  level,
  isNew,
}: {
  url: string;
  displayName: React.ReactNode;
  level: number;
  isNew?: boolean;
}) => {
  return (
    <Item $level={level}>
      <a href={url}>
        <DisplayName>
          {isNew && <NewIcon />}
          <span>{displayName}</span>
        </DisplayName>
      </a>
    </Item>
  );
};

export default MenuItem;
