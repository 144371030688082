import { pulse5Post } from '@subApps/navApp/apis/request';
import useSWR from 'swr';

const useFetchPreDefinedMetrics = type => {
  const { data, error } = useSWR(`/metrics/pre-defined/meta?mode=${type}`, pulse5Post, {
    revalidateOnFocus: false,
  });
  return {
    data,
    error,
    loading: !data,
  };
};

const useFetchUserDefinedMetrics = type => {
  const { data, error } = useSWR(`/metrics/user-defined/meta?mode=${type}`, pulse5Post, {
    revalidateOnFocus: false,
  });
  return {
    data,
    error,
    loading: !data,
  };
};

export const useGetMergedMetrics = ({ type }) => {
  let result = {};
  const { data: preDefinedMetrics, loading: preDefinedLoading } = useFetchPreDefinedMetrics(type);
  const { data: userDefinedMetrics, loading: userDefinedLoading } = useFetchUserDefinedMetrics(
    type,
  );
  result = { ...preDefinedMetrics?.[type], ...userDefinedMetrics?.[type] };
  return {
    result,
    loading: preDefinedLoading || userDefinedLoading,
  };
};
