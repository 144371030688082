import elementReady, { Options as ELOptions } from 'element-ready';

export async function invokeIFCElement(
  eventName: string,
  componentName?: string,
  eventDetails?: Record<string, any>,
  options?: ELOptions,
) {
  const errorMessage = `Failed to load ${componentName || 'component'}, please retry`;

  try {
    const el = await elementReady(`[data-ifc-event="${eventName}"]`, {
      timeout: 60000,
      stopOnDomReady: false,
      ...options,
    });

    if (!el) {
      throw new Error(errorMessage);
    }

    window.dispatchEvent(new CustomEvent(eventName, { detail: eventDetails }));
  } catch {
    throw new Error(errorMessage);
  }
}
