import React from 'react';
import { Space } from 'antd';
import { PRODUCTS } from '@constants/products';
import { useProduct } from '@subApps/navApp/hooks/useGlobal';
import { usePulse5Features } from '@subApps/navApp/hooks/useData';
import { AUTO_INSIGHTS } from '@subApps/navApp/hooks/useData/usePulse5Features/featureNames';
import { get } from 'lodash';
import { isSIOnly } from '@utils/cookie';
import AlertBell from './AlertBell';
import AutomaticInsights from './AutomaticInsights';
import ThemeSwitch from './ThemeSwitch';
import Setting from './Setting';
import Help from './Help';
import { Right, StyledDivider } from './styled';

function RightSection() {
  const { data: product } = useProduct();
  const { data: features } = usePulse5Features();
  const siOnly = isSIOnly();

  // For alpha, do not show help pages for app manager 2.0
  const showAlerts = [PRODUCTS.EI, PRODUCTS.AI, PRODUCTS.APP, PRODUCTS.pulse4].includes(
    product as PRODUCTS,
  );
  const autoInsightsEnabled = get(features, AUTO_INSIGHTS, false);

  return (
    <Right>
      <Space>
        {autoInsightsEnabled && <AutomaticInsights />}
        {!siOnly && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledDivider style={{ height: 30 }} type="vertical" />
            <ThemeSwitch />
          </div>
        )}
        {showAlerts && <AlertBell />}
        <Help />
        <Setting />
      </Space>
    </Right>
  );
}

export default RightSection;
