import React, { useState } from 'react';
import SunIcon from '@resources/icons/light/sun.svg';
import MoonIcon from '@resources/icons/light/moon.svg';
import { Switch } from 'antd';
import { useProduct } from '@subApps/navApp/hooks/useGlobal';
import { usePathName } from '@subApps/common/hooks';
import { ALPHA } from '@constants/theme';
import { toggleDarkAlphaTheme, getAlphaTheme, isDarkAlphaThemeSupported } from '@store/theme';
import { IconWrap } from './styled';

function ThemeSwitch() {
  const { data: product } = useProduct();
  const { pathName } = usePathName();
  const [alphaTheme, setAlphatheme] = useState(getAlphaTheme());

  if (!isDarkAlphaThemeSupported(product, pathName)) {
    return null;
  }

  const handleClick = () => {
    const newTheme = toggleDarkAlphaTheme();
    setAlphatheme(newTheme);
  };

  return (
    <Switch
      style={{ marginRight: 5 }}
      checkedChildren={
        <IconWrap>
          <SunIcon />
        </IconWrap>
      }
      unCheckedChildren={
        <IconWrap>
          <MoonIcon />
        </IconWrap>
      }
      checked={alphaTheme !== ALPHA}
      onClick={handleClick}
    />
  );
}

export default ThemeSwitch;
