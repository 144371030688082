import useSWR from 'swr';
import { pulse5Fetcher } from '@subApps/navApp/apis/request';

function useCheckActiveCustomer(customerId: any = null) {
  return useSWR('/customerCheck', url => pulse5Fetcher(url, customerId), {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  });
}

export default useCheckActiveCustomer;
